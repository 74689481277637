import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAppTabTitle } from 'store/reducers/appSlice/getters';

export const TitleUpdater = () => {
  const appTabTitle = useSelector(getAppTabTitle);
  const { fullName } = appTabTitle;

  useEffect(() => {
    if (fullName) {
      document.title = fullName;
    }
  }, [fullName]);

  return null;
};
