import { initialState } from './reducers/auth/types';
import { initialHistoryStoreState } from './reducers/history/constants';
import { initialBlockVisibilitySettingsState } from './reducers/blockVisibilitySettings/constants';

export const initialLocalStorage = {
  auth: initialState,
  history: initialHistoryStoreState,
  blockVisibilitySettings: initialBlockVisibilitySettingsState,
};

export const whiteListLocalStorage = ['auth', 'history', 'blockVisibilitySettings'];
