import { FlexContainer } from 'styles/FlexContainer';
import React, { MouseEventHandler, useMemo } from 'react';
import TextField from 'modules/ui/TextField';
import { PriorityChangerButtons, PriorityChangerButtonsProps } from 'modules/settingsContainer/PriorityChangerButtons';
import { NoopType, ReactChildrenType } from 'types/global';
import { ClosedEyeIcon, CopyIcon, DeleteIcon, EyeIcon as DefaultEyeIcon, RestrictAccessIcon } from 'assets/icons/withContainer';
import { SettingsFieldWrapper, SettingsFieldWrapperProps } from 'modules/ui/wrappers/SettingsFieldWrapper/styles';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { MenuListComponent } from 'components/shared/ui/menu/MenuIconList';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph } from 'styles/TextsElements';

interface SettingsFieldEntryProps extends PriorityChangerButtonsProps, SettingsFieldWrapperProps {
  children?: ReactChildrenType;
  fieldValue: string;
  onFieldChange: (fieldValue: string) => void;
  onClickHidden: NoopType;
  onSetupClick?: MouseEventHandler<HTMLButtonElement>;
  setupIsOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onCopyClick?: NoopType;
  onRestrictAccess?: NoopType;
  isHidden?: boolean;
  EyeIcon?: JSX.Element;
  disabledRestrictAccess?: boolean;
  disabledOptionsSourceMenu?: boolean;
  disablePriority?: boolean;
  restrictAccessList?: { name: string; id: string }[];
}

export const SettingsPagesFieldEntry = ({
  fieldValue,
  onFieldChange,
  onClickHidden,
  isHidden,
  onUpClick,
  onDownClick,
  onRestrictAccess,
  isSelected,
  isActive,
  onClick,
  children,
  onCopyClick,
  EyeIcon,
  disabledOptionsSourceMenu = false,
  disabledRestrictAccess = false,
  disablePriority,
  restrictAccessList,
}: SettingsFieldEntryProps) => {
  const optionsSourceMenu = useMemo(
    (): Option[] => [
      {
        name: isHidden ? (EyeIcon ? 'Применить' : 'Показать в навигации') : 'Скрыть из навигации',
        value: 'hidden',
        Icon: isHidden ? ClosedEyeIcon : DefaultEyeIcon,
        onClick: onClickHidden,
      },
      {
        name: 'Дублировать',
        value: 'copy',
        Icon: CopyIcon,
        onClick: onCopyClick,
        disabled: !isSelected,
      },
      {
        name: 'Ограничить доступ',
        value: 'restrictAccess',
        Icon: RestrictAccessIcon,
        onClick: onRestrictAccess,
        disabled: !onRestrictAccess,
      },
      {
        name: 'Удалить',
        value: 'delete',
        Icon: DeleteIcon,
        onClick: () => {},
      },
    ],
    [isHidden, EyeIcon, onClickHidden, onCopyClick, isSelected, onRestrictAccess],
  );

  return (
    <SettingsFieldWrapper onClick={onClick} isSelected={isSelected} isActive={isActive}>
      <FlexContainer alignItems="center" gap="4px">
        <TextField
          name="xAxisName"
          width="100%"
          needBackground={false}
          needBorderBottom={false}
          value={fieldValue}
          useDebounce
          onChange={(e) => onFieldChange(e.target.value)}
        />
        {isSelected && !disabledRestrictAccess && !!restrictAccessList?.length && (
          <TooltipIconButton
            title={
              <FlexContainer flexDirection="column" maxHeight="300px" overflow="auto">
                {restrictAccessList?.map(({ id, name }) => (
                  <PrimaryTextParagraph
                    key={id}
                    color={`var(${ColorVarsEnum.Sub_Level_1})`}
                    lineHeight="12px"
                    fontSize="12px"
                    padding="9px 8px"
                  >
                    {name}
                  </PrimaryTextParagraph>
                ))}
              </FlexContainer>
            }
            heightSize="small"
            needBackground={false}
            leftIcon={<RestrictAccessIcon />}
            iconSize="big"
            onClick={onRestrictAccess}
          />
        )}
        {EyeIcon && (
          <TooltipIconButton
            title={isHidden ? (EyeIcon ? 'Применить' : 'Показать в навигации') : 'Скрыть из навигации'}
            heightSize="small"
            needBackground={false}
            leftIcon={isHidden ? <ClosedEyeIcon /> : EyeIcon || <DefaultEyeIcon />}
            iconSize="big"
            onClick={onClickHidden}
          />
        )}
        {isHidden && (
          <TooltipIconButton
            title={isHidden ? (EyeIcon ? 'Применить' : 'Показать в навигации') : 'Скрыть из навигации'}
            heightSize="small"
            needBackground={false}
            leftIcon={isHidden ? <ClosedEyeIcon /> : EyeIcon || <DefaultEyeIcon />}
            iconSize="big"
            onClick={onClickHidden}
          />
        )}
        {isSelected && !disablePriority && <PriorityChangerButtons onDownClick={onDownClick} onUpClick={onUpClick} />}
        {isSelected && !disabledOptionsSourceMenu && <MenuListComponent options={optionsSourceMenu} />}
      </FlexContainer>

      {isSelected && children && (
        <FlexContainer alignItems="center" gap="10px">
          <FlexContainer flex="1 1 0">{children}</FlexContainer>
        </FlexContainer>
      )}
    </SettingsFieldWrapper>
  );
};
