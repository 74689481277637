import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { EntityType, GroupType, IdInterface, LoadingInterface, NameInterface, ViewFontSettingsInterface } from 'types/store';
import {
  BorderSpecificValueEnum,
  ColorByInterface,
  ColorByItem,
  ColorByRuleInterface,
  ColorValuesByThemeType,
  ElementSpecificValueEnum,
  GradientColorByInterface,
  IsShowInterface,
  SortOrderEnum,
} from 'store/reducers/visualisations/types';
import { PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import type { RightAndLeftType } from 'types/styles';

export interface AccessInterface extends IdInterface, NameInterface {
  entity: EntityType;
  type: GroupType;
}

export interface AddUsersGroupsSourceAccessPayload {
  sourceId: string;
  groups?: string[];
  users?: string[];
  type: 'owner' | 'reader';
}

interface UpdateSourceAccess {
  sourceId: string;
  type: 'owner' | 'reader';
}

export interface UpdateGroupSourceAccessByIdPayload extends UpdateSourceAccess {
  groupId: string;
}

export interface UpdateUserSourceAccessByIdPayload extends UpdateSourceAccess {
  userId: string;
}

export interface AvatarProjectInterface {
  avatar: File | null;
  projectId: string;
}

export type GetUsersAccessNoGroupPayload = {
  groupId: string;
};

export interface GroupsInterface extends LoadingInterface {
  groupsList: GroupsListInterface[];
}

export enum AstOperatorEnum {
  IN = 'IN',
  BETWEEN = 'BETWEEN',
}

export type CronHour = FastBoard.API.CronHour;
export type CronDay = FastBoard.API.CronDay;
export type CronWeek = FastBoard.API.CronWeek;
export type CronMonth = FastBoard.API.CronMonth;
export type CronSchedule = FastBoard.API.CronSchedule;

export type CronDateTypes = CronHour | CronDay | CronWeek | CronMonth | CronSchedule;

export interface RLSInterface {
  type: EntityType;
  entityId: string;
}

export enum WidgetRuNameEnum {
  single = 'Одиночный',
  multiple = 'Множественный',
  date = 'Дата',
  bubble = 'Пузырьковая диаграмма',
  heatmap = 'Тепловая карта',
  lineAndBar = 'Линейная диаграмма',
  pie = 'Круговая диаграмма',
  table = 'Таблица',
  text = 'Текст',
  tree = 'Дерево',
  waterfall = 'Водопадная диаграмма',
  group = 'Группа',
  frame = 'iFrame',
  geometricShapes = 'Фигура',
  variable = 'Триггер ',
}

export interface ColorSettingsBase {
  elementColorBy: ColorByInterface;
  isActive: boolean;
}

export interface ColorSettingsIncision extends ColorSettingsBase {
  elementColor: PaletteValuesByThemeType | null;
}

export interface ColorSettingsIndicator extends ColorSettingsBase {
  elementColor: ColorValuesByThemeType | null;
}

export type TableDataValue = string | number | (string | number | null)[];

export interface GradientColorSettingsBase {
  elementColorBy: GradientColorByInterface;
  isActive: boolean;
}

export interface GradientColorSettings extends GradientColorSettingsBase {
  elementColor: ColorValuesByThemeType | null;
}

export interface TextSettingInterface {
  text: string;
  isShow: boolean;
}

export enum OperationTypeEnum {
  SUM = 'sum',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  COUNT = 'count',
  COUNT_UNIQUE = 'countUnique',
  TEXT = 'text',
}

export enum RuleEnum {
  TEXT_CONTAINS = 'textContains',
  TEXT_NOT_CONTAINS = 'textNotContains',
  TEXT_STARTS_WITH = 'textStartsWith',
  TEXT_ENDS_WITH = 'textEndsWith',
  TEXT_EXACT = 'textExact',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  GREATER_OR_EQUAL = 'greaterOrEqual',
  LESS_OR_EQUAL = 'lessOrEqual',
  EQUAL = 'equal',
  NOT_EQUAL = 'notEqual',
  BETWEEN = 'between',
  NOT_BETWEEN = 'notBetween',
  DATE_EXACT = 'dateExact',
  DATE_BEFORE = 'dateBefore',
  DATE_AFTER = 'dateAfter',
  DATE_BETWEEN = 'dateBetween',
  DATE_NOT_BETWEEN = 'dateNotBetween',
}

export interface FrameSettingInterface extends IsShowInterface {
  text: string;
  isShow: boolean;
}

export interface HintInterface extends TextSettingInterface {
  position: RightAndLeftType;
}

export interface TextSettingColorInterface extends IsShowInterface {
  text: string;
  code?: {
    text: string;
    isShow: boolean;
  };
  isShow: boolean;
  fontStyle?: {
    bold: boolean;
    italic: boolean;
    underline: boolean;
  };
  fontSize?: number;
  lineHeight?: number;
  letterSpacing?: number;
  wordSpacing?: number;
  fontColor?:
    | PaletteValuesByThemeType
    | ColorValuesByThemeType
    | (PaletteValuesByThemeType | null)[]
    | (ColorValuesByThemeType | null)[]
    | null;
  backgroundColor?: string | ColorValuesByThemeType | null;
  backgroundOpacity?: number;
  isActiveStyle?: boolean;
  isActiveFontColor?: boolean;
  isActiveBackgroundColor?: boolean;
  opacity?: number;
  padding?: {
    horizontal: number;
    vertical: number;
    isConnection: boolean;
  };
  changeFillColor?: boolean;
  alignment?: {
    horizontal: 'flex-start' | 'center' | 'flex-end';
    vertical: 'flex-start' | 'center' | 'flex-end';
  };
  fontColorBy?: {
    type: 'default' | 'custom';
    isActive: boolean;
    byCondition: {
      colors?: ColorByItem[] | null;
      sqlCondition: string | null;
      alias: string;
    };
    byValue: {
      alias: string;
      colors: string | null;
    };
    byValueSpecific: {
      alias: string;
      colors: string | null;
      borderType: BorderSpecificValueEnum;
      valueType: ElementSpecificValueEnum;
      direction: SortOrderEnum;
    };
    byRule: {
      alias: string;
      defaultColor: PaletteValuesByThemeType | null;
      sqlCondition: string | null;
      rules: ColorByRuleInterface[];
    };
  };
  backgroundColorBy?: {
    type: 'default' | 'custom';
    isActive: boolean;
    byCondition: {
      colors: ColorByItem[] | null;
      sqlCondition: string | null;
      alias: string;
    };
    byValue: {
      alias: string;
      colors: string | null;
    };
    byValueSpecific: {
      alias: string;
      colors: string | null;
    };
    byRule: {
      alias: string;
      defaultColor: PaletteValuesByThemeType | null;
      sqlCondition: string | null;
      rules: ColorByRuleInterface[];
    };
  };
  fontFamilySettings?: ViewFontSettingsInterface;
}

export interface convertHexToHslResultInteface {
  success: boolean;
  message?: string;
  result: {
    h: number;
    s: number;
    l: number;
    opacity: number;
    hex: string;
  };
}

export interface VariantsFontsProjectSettingsInterface {
  fontFamily: string;
  style: string;
  weight: string;
  urlTTF: string;
  urlWOFF: string;
}

export interface FontsProjectSettingsListInterface {
  fontsFamily: string;
  variants: VariantsFontsProjectSettingsInterface[];
}

export interface FontsProjectSettingsAndSelectFontListInterface {
  allFonts: FontsProjectSettingsListInterface[];
  currentFont: string;
}

export interface FontsProjectSettingsInterface extends LoadingInterface {
  fontsProjectSettingsList: FontsProjectSettingsListInterface[];
  currentFont: string;
}

export interface FontProjectInterface {
  fontProject: string;
}
