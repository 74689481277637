import { ProjectIdParam } from 'app/providers/router/types';
import {
  changePaletteColor,
  defaultActiveColor,
  exportJsonFile,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/constants';
import {
  ActiveColorInterface,
  AddColorType,
  ChangeColorsType,
  ChangeColorType,
  ChangeGroupNameType,
  CopyGroupType,
  DeleteColorType,
  DeleteGroupType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import { MovePaletteGroupInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/types';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import {
  addColorToGroupByCurrentPaletteAction,
  addGroupToCurrentPaletteAction,
  addNewPaletteAction,
  addNewPaletteByCurrentPaletteAction,
  removeColorFromGroupByCurrentPaletteAction,
  removeGroupFromPaletteByIdAction,
  removePaletteByIdAction,
  updatePaletteAction,
} from 'store/reducers/palettes/actions';
import { getPaletteColorItem, getPaletteItem } from 'store/reducers/palettes/constants';
import { getActivePalette, getPalettesAsArray } from 'store/reducers/palettes/getters';
import { GroupItemInterface } from 'store/reducers/palettes/types';
import { updatePaletteLinkAction } from 'store/reducers/themes/actions';
import { getActiveThemeId } from 'store/reducers/themes/getters';
import { useToggle } from 'utils/hooks/toggle';
import { getHls, moveArrayItem } from 'utils/utils';
import { isTheJSONvalid, isValidGroupsColorJSON } from 'utils/json';
import { updatePaletteById } from 'store/reducers/palettes';
import Snackbar from 'services/Snackbar';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { ExportDataInProject } from 'components/shared/ExportArrayDataInProject';
import { NameModal } from 'components/projectsManager/Modals/NameModal';
import { HexAndHLSColorInterface, ModeForm } from 'types/store';
import { v4 } from 'uuid';
import { useModalState } from 'utils/hooks/modalState';

export const usePalettesTab = () => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<ProjectIdParam>();

  const activePalette = useSelector(getActivePalette);
  const activeThemeId = useSelector(getActiveThemeId);
  const palettes = useSelector(getPalettesAsArray);

  const [synchronizationGroupMode, toggleSynchronizationGroupMode] = useToggle(false);
  const [activeColor, setActiveColor] = useState<ActiveColorInterface>(defaultActiveColor);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useModalState();

  // ------------------- Palette Actions -------------------
  const handlePaletteChange = (paletteId: string) => {
    activeThemeId && dispatch(updatePaletteLinkAction({ themeId: activeThemeId, paletteId }));
  };

  const handleAddPalette = useCallback(
    (name: string) => {
      dispatch(addNewPaletteAction(getPaletteItem({ name })));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCopyPalette = useCallback(
    () => {
      dispatch(addNewPaletteByCurrentPaletteAction());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handlePaletteNameChange = useCallback(
    (name: string) => {
      if (!activePalette) return;
      dispatch(updatePaletteAction({ ...activePalette, name }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  const handleDeletePalette = useCallback(
    () => {
      if (!activePalette) return;
      dispatch(removePaletteByIdAction(activePalette.id));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  // ------------------- Group Actions -------------------
  const handleDeleteGroup = useCallback<DeleteGroupType>(
    ({ groupId }) => {
      if (!activePalette) return;
      dispatch(removeGroupFromPaletteByIdAction({ id: activePalette.id, groupId }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  const handleAddGroup = useCallback(
    ({ color }: { color: HexAndHLSColorInterface | null }) => {
      dispatch(
        addGroupToCurrentPaletteAction({
          group: { colors: [getPaletteColorItem({ ...color })] },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCopyGroup = useCallback<CopyGroupType>(
    (props) => {
      dispatch(addGroupToCurrentPaletteAction(props));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMovePaletteGroup = useCallback(
    ({ groupId, moveTo }: MovePaletteGroupInterface) => {
      if (!activePalette) return;

      const groups = activePalette.groups;
      const indexOfIncision = groups.findIndex(({ id }) => id === groupId);
      const { newArray } = moveArrayItem(groups, indexOfIncision, moveTo);
      dispatch(updatePaletteAction({ ...activePalette, groups: [...newArray] }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  const handleGroupNameChange = useCallback<ChangeGroupNameType>(
    ({ groupId, name }) => {
      if (!activePalette) return;
      dispatch(
        updatePaletteAction({
          ...activePalette,
          groups: activePalette.groups.map((group) => (group.id === groupId ? { ...group, name } : group)),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  const handleToggleShowGroupNames = useCallback(
    () => {
      if (!activePalette) return;
      dispatch(
        updatePaletteAction({
          ...activePalette,
          showGroupNames: !activePalette.showGroupNames,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  // ------------------- Color Actions -------------------
  const handleDeleteColor = useCallback<DeleteColorType>(
    ({ colorId, groupId }) => {
      dispatch(removeColorFromGroupByCurrentPaletteAction({ groupId, colorId }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleAddColor = useCallback<AddColorType>(
    async ({ color, groupId }) => {
      const newColor = color ? getPaletteColorItem({ ...color }) : getPaletteColorItem({ id: v4() });
      const resultAction = await dispatch(
        addColorToGroupByCurrentPaletteAction({
          groupId,
          color: newColor,
        }),
      );

      if (addColorToGroupByCurrentPaletteAction.fulfilled.match(resultAction)) {
        return resultAction.payload as { groupId: string; colorId: string };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleColorChange = useCallback<ChangeColorType>(
    ({ groupId, colorId, color }) => {
      if (!activePalette) return;
      dispatch(
        updatePaletteAction({
          ...activePalette,
          groups: activePalette.groups.map((group) =>
            group.id === groupId
              ? {
                  ...group,
                  colors: group.colors.map((oldColor) => (oldColor.id === colorId ? { ...oldColor, ...color } : oldColor)),
                }
              : group,
          ),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  const handleColorsChange = useCallback<ChangeColorsType>(
    ({ groupId, color }) => {
      if (!activePalette || !color) return;
      dispatch(
        updatePaletteAction({
          ...activePalette,
          groups: activePalette.groups.map((group) =>
            group.id === groupId
              ? {
                  ...group,
                  colors: group.colors.map((oldColor) => ({
                    ...oldColor,
                    ...color,
                    hex: getHls({ ...oldColor, ...color }),
                  })),
                }
              : group,
          ),
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette],
  );

  // ------------------- Import/Export Actions -------------------
  const handleExportDataChange = useCallback(
    (value: string[]) => {
      if (!activePalette) return;
      const exportGroupColors = activePalette.groups.filter(({ id }) => value.includes(id));
      const data = JSON.stringify(exportGroupColors);
      exportJsonFile(data, 'colors');
    },
    [activePalette],
  );

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>, replaceColors: boolean) => {
      if (!activePalette || !projectId) return;

      const file = event.target.files?.[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = (e.target as FileReader).result;
        if (
          typeof result === 'string' &&
          isTheJSONvalid<GroupItemInterface[]>({
            json: result,
            validateFunc: isValidGroupsColorJSON,
          })
        ) {
          const groups: GroupItemInterface[] = JSON.parse(result);
          const updatedGroups = changePaletteColor({
            replaceColors,
            groups,
            oldGroups: activePalette.groups,
          });
          dispatch(updatePaletteById({ ...activePalette, groups: updatedGroups }));
        } else {
          Snackbar.show('Невалидный файл', 'error');
        }
      };
      reader.readAsText(file);
      event.target.value = '';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activePalette, projectId],
  );

  const handleImportClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        openConfirmationModalAction({
          titleText: 'Импорт цветов',
          onConfirm: () => handleFileUpload(event, true),
          onCancel: () => handleFileUpload(event, false),
          confirmationButtonText: 'Заменить',
          cancelButtonText: 'Нет',
          subTitleText: 'Заменить цвета при совпадении имен?',
          width: '320px',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleFileUpload],
  );

  const handleCloseExportModal = useCallback(
    () => {
      dispatch(closeModalAction('exportFlowers'));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleExportClick = useCallback(
    () => {
      if (!projectId || !activePalette) return;
      dispatch(
        openModalTypedAction({
          Component: ExportDataInProject,
          componentProps: {
            onClose: handleCloseExportModal,
            onCancel: handleCloseExportModal,
            title: 'Выберите группы цветов для экспорта',
            errorMessage: 'Выберите хотя бы один цвет для экспорта',
            onChange: handleExportDataChange,
            value: activePalette.groups.map(({ name, id }) => ({
              name,
              id,
              isChecked: false,
            })),
          },
          modalSettings: {
            headerText: 'Экспорт цветов',
            width: '280px',
          },
          name: 'exportFlowers',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, activePalette, handleCloseExportModal, handleExportDataChange],
  );

  const handleFileButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  // ------------------- Активный цвет (мемоизация) -------------------
  const activeColorData: HexAndHLSColorInterface | null = useMemo(() => {
    if (!activePalette) return null;
    const { activeColorIds } = activeColor;
    if (!activeColorIds || !activeColorIds.colorId) return null;

    const { groupId, colorId } = activeColorIds;
    const group = activePalette.groups.find((g) => g.id === groupId);
    if (!group) return null;
    const colorData = group.colors.find((c) => c.id === colorId);
    if (!colorData) return null;

    const { h, s, l, opacity, hex, name } = colorData;
    return { h, s, l, opacity, hex, name };
  }, [activeColor, activePalette]);

  // ------------------- Модальное окно для добавления/редактирования палитры -------------------
  const handleClosePaletteModal = useCallback(
    () => {
      dispatch(closeModalAction('onAddAndEditPaletteModal'));
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOpenPaletteModal = useCallback(
    (mode: ModeForm, value?: string) => {
      dispatch(
        openModalTypedAction({
          Component: NameModal,
          componentProps: {
            onClose: handleClosePaletteModal,
            onClick: (val: string) => (mode === 'edit' ? handlePaletteNameChange(val) : handleAddPalette(val)),
            value,
            buttonSaveText: mode === 'edit' ? 'Переименовать' : 'Создать',
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            maxHeight: '168px',
            headerText: mode === 'edit' ? 'Переименование палитры' : 'Создание палитры',
          },
          name: 'onAddAndEditPaletteModal',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClosePaletteModal, handlePaletteNameChange, handleAddPalette],
  );

  return {
    activePalette,
    activeThemeId,
    palettes,

    synchronizationGroupMode,
    toggleSynchronizationGroupMode,
    activeColor,
    setActiveColor,
    fileInputRef,
    isOpen,
    onOpen,
    onClose,
    activeColorData,

    handlePaletteChange,
    handleAddPalette,
    handleCopyPalette,
    handlePaletteNameChange,
    handleDeletePalette,
    handleOpenPaletteModal,

    handleDeleteGroup,
    handleAddGroup,
    handleCopyGroup,
    handleMovePaletteGroup,
    handleGroupNameChange,
    handleToggleShowGroupNames,

    handleDeleteColor,
    handleAddColor,
    handleColorChange,
    handleColorsChange,

    handleFileButtonClick,
    handleImportClick,
    handleExportClick,
  };
};
