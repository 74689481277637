import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import { deleteFromLayerFunc, initialBoardStoreState } from 'store/reducers/board/constants';
import { BufferType, StyleBufferType, UpdateLayerPayload, UpdateLayersPayload } from 'store/reducers/board/types';
import { loadLayersByPageIdAction, loadLayersFromSnapshotAction } from 'store/reducers/board/actions';
import { setSliceFn } from 'constants/store';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { BoardElementActivateEnum } from 'store/reducers/groupsVisualisations/types';

export const boardSlice = createSlice({
  name: 'board',
  initialState: initialBoardStoreState,
  reducers: {
    setBuffer: (state, { payload: buffer }: PayloadAction<BufferType[]>) => {
      state.buffer = buffer;
    },

    setActiveBoardElement: (
      state,
      {
        payload: { id: newElementId, type = BoardElementActivateEnum.SINGLE },
      }: PayloadAction<{ id: string | string[] | null; type?: BoardElementActivateEnum }>,
    ) => {
      if (isArray(newElementId)) {
        state.activeBoardElements = newElementId;
        return;
      }

      if (isString(newElementId)) {
        const currentElements = state.activeBoardElements ?? [];

        if (type === BoardElementActivateEnum.MULTIPLE) {
          state.activeBoardElements = currentElements.includes(newElementId)
            ? currentElements.filter((id) => id !== newElementId)
            : [...currentElements, newElementId];
        } else {
          state.activeBoardElements = [newElementId];
        }
      }

      if (isNull(newElementId)) {
        state.activeBoardElements = null;
      }
    },

    setActiveBoardElementInViewMode: (state, { payload: activeBoardElementInViewMode }: PayloadAction<string | null>) => {
      state.activeBoardElementInViewMode = activeBoardElementInViewMode;
    },

    addToLayer: (state, { payload: { id, pageId } }: PayloadAction<UpdateLayerPayload>) => {
      const pageLayers = state.pagesLayers[pageId];

      if (pageLayers) {
        state.pagesLayers[pageId] = [...pageLayers, id];
      }
    },

    deleteFromLayer: deleteFromLayerFunc,

    deleteLayer: (state, { payload: pageId }: PayloadAction<string>) => {
      if (state.pagesLayers[pageId]) {
        delete state.pagesLayers[pageId];
      }
    },

    updateLayers: (state, { payload: { pageId, layers } }: PayloadAction<UpdateLayersPayload>) => {
      state.pagesLayers = {
        ...state.pagesLayers,
        [pageId]: [...layers],
      };
    },

    toggleInfluenceFiltersMode: (state) => {
      state.editInfluenceFiltersMode = !state.editInfluenceFiltersMode;
    },

    unsetInfluenceFiltersMode: (state) => {
      state.editInfluenceFiltersMode = false;
    },

    addToAlreadyLoadedByPageId: (state, { payload: pageId }: PayloadAction<string>) => {
      const currentState = state.alreadyLoadedContent.layers || new Set<string>();
      currentState.add(pageId);

      state.alreadyLoadedContent.layers = currentState;
    },

    addStyleBuffer: (state, { payload: styleBuffer }: PayloadAction<StyleBufferType>) => {
      state.styleBuffer = styleBuffer;
    },

    //----------------------RETURN FOR HISTORY----------------------
    returnFromLayer: deleteFromLayerFunc,

    setSlice: setSliceFn,
  },

  extraReducers: (builder) => {
    builder.addCase(
      loadLayersByPageIdAction.fulfilled,
      (
        state,
        {
          payload: pagesLayers,
          meta: {
            arg: { pageId },
          },
        },
      ) => {
        state.pagesLayers = { ...state.pagesLayers, ...pagesLayers };
        state.serverStateOfPagesLayers = { ...state.pagesLayers, ...pagesLayers };

        const newAlreadyLoadedContent = state.alreadyLoadedContent.layers;
        newAlreadyLoadedContent.add(pageId);
        state.alreadyLoadedContent.layers = newAlreadyLoadedContent;
      },
    );

    builder.addCase(loadLayersFromSnapshotAction.fulfilled, (state, { payload: pagesLayers }) => {
      state.pagesLayers = pagesLayers;
    });
    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfPagesLayers) {
        state.serverStateOfPagesLayers = state.pagesLayers;
      }
    });
  },
});

export const {
  setBuffer,
  setActiveBoardElement,
  setActiveBoardElementInViewMode,
  addToLayer,
  deleteFromLayer,
  updateLayers,
  returnFromLayer,
  setSlice,
  deleteLayer,
  addStyleBuffer,
  toggleInfluenceFiltersMode,
  unsetInfluenceFiltersMode,
  addToAlreadyLoadedByPageId,
} = boardSlice.actions;

export default boardSlice.reducer;
