import { fraction } from 'mathjs';

import { Formatting, FormattingInterface, FormattingItem } from 'store/reducers/visualisations/types';
import { BoardPositionConfigInterface, PositionSettingType, SizeOptionInterface, TextAlignSettingType } from 'types/store';
import { ContainerValuesEnum } from 'store/reducers/groupsVisualisations/types';
import { customAliasSeparator } from 'constants/global';

interface FormatDataParams
  extends Pick<FormattingItem, 'meta'>,
    Pick<FormattingInterface['formats'], 'numberOfZeros' | 'numeric'> {
  text: string;
}

export const chain: Record<Formatting, (params: FormatDataParams) => string> = {
  numerical: ({ text, numberOfZeros, numeric }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text).toFixed(numberOfZeros);
    const numericType = new Intl.NumberFormat('ru-RU', { minimumFractionDigits: numberOfZeros }).format(Number(numberFromText));

    return numeric ? numericType : numberFromText;
  },
  percent: ({ text, numberOfZeros, numeric }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text);
    const numberPercent = Number(+numberFromText * 100).toFixed(numberOfZeros) + '%';

    const numericType = new Intl.NumberFormat('ru-RU', {
      style: 'percent',
      minimumFractionDigits: numberOfZeros,
    }).format(Number(numberFromText));

    return numeric ? numericType : numberPercent;
  },
  money: ({ text, numberOfZeros, meta }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text).toFixed(numberOfZeros);
    return new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      minimumFractionDigits: numberOfZeros,
      currency: meta?.money ? meta?.money : 'RUB',
    }).format(Number(numberFromText));
  },
  fraction: ({ text }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const numberFromText = changeComaToDot(text);

    const fractionFormatting = fraction(numberFromText);
    const { s, d, n } = fractionFormatting;
    const fractionFormattingS = s === 1 ? '' : s + ' ';
    const fractionFormattingD = d === 1 ? '' : '/' + d;

    return fractionFormattingS + n + fractionFormattingD;
  },
  exponential: ({ text }) => {
    if (!isNumeric(Number(changeComaToDot(text)))) {
      return text;
    }

    const questionText = text.replace(/[^%]/g, '');
    const res = text.replace(/[^.\d]/g, '');

    return Number(res).toExponential() + questionText;
  },
};

export const changeComaToDot = (number: string) => {
  const changeCommaToDot = new RegExp('(\\d+)([,.]?)(\\d*)', 'g');

  return Number(
    number.replace(changeCommaToDot, (str, p1, p2, p3) => {
      if (p3 == '') return p1;
      return p1 + '.' + p3;
    }),
  );
};

export const numberOfSymbolsAfterComma = (number: string) => number?.toString().match(/\.(\d+)/)?.[1].length;

export const isNumeric = (n: number) => !isNaN(n);

export const fractional = (number: string) => {
  const trunc = Math.trunc(Number(number));
  const frac = !Number.isInteger(Number(number))
    ? Math.floor((Number(number) % 1) * Math.pow(10, Number(numberOfSymbolsAfterComma(number)))) +
      '/' +
      Math.pow(10, Number(numberOfSymbolsAfterComma(number))) +
      ''
    : '';

  return trunc + '' + frac;
};

export const chainFormatter = (formatters: FormattingInterface['formats'], text: string) => {
  text = chain[formatters.formattingItem.formattingType]({
    text,
    numberOfZeros: formatters.numberOfZeros,
    meta: formatters.formattingItem.meta,
    numeric: formatters.numeric,
  });
  const formattersText = formatters.editText.isActive ? formatters.editText.text : '';

  return text + (formattersText ? ' ' + formattersText : '');
};

export const formattingNameIncision = (value: string): string => {
  return value.replace(/["'`]/g, '');
};

export const formattingNameTextAlign = (value: PositionSettingType): TextAlignSettingType => {
  return value.replace(/^flex-/, '') as TextAlignSettingType;
};

export const getCustomAliasName = ({ nameIncision, alias }: { nameIncision: string; alias: string }) => {
  return `${nameIncision}${customAliasSeparator}${alias}`;
};

export const parseCustomAliasName = (customAlias: string) => {
  const [nameIncision, alias] = customAlias.split(customAliasSeparator);
  return { nameIncision, alias };
};

export const getAdaptiveSize = ({
  heightOption,
  widthOption,
  positionConfig,
}: {
  heightOption?: SizeOptionInterface;
  widthOption?: SizeOptionInterface;
  positionConfig: BoardPositionConfigInterface;
}) => {
  const { minWidth, maxWidth, minHeight, maxHeight } = positionConfig;
  return {
    minHeight: heightOption?.value === ContainerValuesEnum.ADAPTIVE && minHeight,
    maxHeight: heightOption?.value === ContainerValuesEnum.ADAPTIVE && maxHeight,
    minWidth: widthOption?.value === ContainerValuesEnum.ADAPTIVE && minWidth,
    maxWidth: widthOption?.value === ContainerValuesEnum.ADAPTIVE && maxWidth,
  };
};

export const isValidHex = (hex: string): boolean => {
  // Регулярное выражение для проверки форматов: #RGB, #RRGGBB, #RGBA, #RRGGBBAA
  const hexRegex = /^#?([a-fA-F\d]{3}|[a-fA-F\d]{6}|[a-fA-F\d]{4}|[a-fA-F\d]{8})$/;

  return hexRegex.test(hex);
};

export const filterDigitsOnly = (value: string) => {
  return value.replace(/\D/g, '');
};

export const filterSingleDigit = (value: string) => {
  const match = value.match(/[1-8]/);
  return match ? match[0] : '';
};
