import { NoopType } from 'types/global';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { AddCircleIcon } from 'assets/icons/withContainer';
import React from 'react';
import { AddColorButtonWrapper, type AddColorButtonWrapperProps } from 'modules/ui/buttons/AddColorButton/styles';

interface AddColorButtonProps extends AddColorButtonWrapperProps {
  onAdd: NoopType;
}

export const AddColorButton = ({ onAdd, pickerItemDirection }: AddColorButtonProps) => (
  <AddColorButtonWrapper onClick={onAdd} pickerItemDirection={pickerItemDirection}>
    <IconWrapper
      cursor="pointer"
      hoverColorVar={ColorVarsEnum.Level_3}
      colorVar={ColorVarsEnum.Level_3}
      iconWidth="30px"
      iconHeight="30px"
      Icon={AddCircleIcon}
    />
  </AddColorButtonWrapper>
);
