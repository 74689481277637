import { HexColorEditor } from 'modules/ui/colors/HexColorEditor';
import { HlsColorEditor, HlsColorEditorProps } from 'modules/ui/colors/HlsColorEditor';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorEditType, HexAndHLSColorInterface } from 'types/store';
import { convertHexToHslValues } from 'utils/utils';
import { TextField } from 'modules/ui/index';
import snackbar from 'services/Snackbar';
import { NoopType } from 'types/global';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { CopyIcon, DeleteIcon } from 'assets/icons/withContainer';

interface HexAndHlsColorEditorProps extends Pick<HlsColorEditorProps, 'disableHls'> {
  value: HexAndHLSColorInterface;
  onChange: (color: HexAndHLSColorInterface, type: ColorEditType) => void;
  disabledName?: boolean;
  onChangeCopyColor?: NoopType;
  onDeleteGroupColor?: NoopType;
  disabledDeleteGroupColor?: boolean;
}

export const HexAndHlsColorEditor = ({
  value,
  onChangeCopyColor,
  onChange,
  disableHls,
  disabledDeleteGroupColor,
  onDeleteGroupColor,
  disabledName = false,
}: HexAndHlsColorEditorProps) => {
  const onHexColorChange = (hex: string) => {
    const { success, message, result } = convertHexToHslValues(hex);

    if (!success) {
      message && snackbar.show(message, 'error');
    }

    onChange({ ...value, ...result }, 'hex');
  };

  return (
    <FlexContainer position="relative" flexDirection="column" width="100%">
      <FlexContainer flexDirection="row" alignItems="flex-end" gap="8px">
        <HexColorEditor value={value.hex} onChange={onHexColorChange} />

        {!disabledName && (
          <TextField
            name="nameHex"
            width="130px"
            widthContainer="100px"
            placeholder="Без названия"
            value={value.name || ''}
            needBackground={false}
            needBorderBottom={false}
            onChange={(e) => onChange({ ...value, name: e.target.value }, 'name')}
          />
        )}
      </FlexContainer>

      <HlsColorEditor disableHls={disableHls} value={value} onChange={(color, type) => onChange({ ...value, ...color }, type)} />

      <FlexContainer position="absolute" top="0px" right="-16px" flexDirection="column" gap="8px">
        {onChangeCopyColor && <TooltipIconButton title="Создать образец" leftIcon={<CopyIcon />} onClick={onChangeCopyColor} />}
        {onDeleteGroupColor && (
          <TooltipIconButton
            disabled={disabledDeleteGroupColor}
            title="Удалить группу"
            leftIcon={<DeleteIcon />}
            onClick={onDeleteGroupColor}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};
