import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import {
  AlignmentInterface,
  BackgroundSettingsInterface,
  ColorByInterface,
  ColorSettingsStateInterface,
  IndentationInterface,
  IsActiveInterface,
  LimitSettingInterface,
  OverflowPositionInterface,
} from 'store/reducers/visualisations/types';
import { BorderStyleType } from 'types/styles';
import { MapRecordType } from './global';
import { ContainerValuesEnum } from 'store/reducers/groupsVisualisations/types';
import { VariantsFontsProjectSettingsInterface } from 'types/types';

export interface IsRealDataInterface {
  isRealData: boolean;
}

export interface NameFromDatabaseInterface {
  nameFromDatabase: boolean;
}

export interface NameInterface {
  name: string;
}

export interface FieldNameInterface extends NameInterface {
  fieldName: string | null;
}

export interface FictionalDataInterface {
  fictionalData: string[];
}

export type PositionSettingType = 'center' | 'flex-start' | 'flex-end';
export type AbsolutePositionSettingType = 'column' | 'row' | 'column-reverse' | 'row-reverse';
export type TextAlignSettingType = 'center' | 'start' | 'end';
export type GeometryShapesStrategyType = 'rectangle' | 'circle' | 'triangle' | 'line';

export enum PositionSettingEnum {
  CENTER = 'center',
  FLEXSTART = 'flex-start',
  FLEXEND = 'flex-end',
}

export type SwitchAutoSettingType = 'auto' | 'manually';

export type SwitchFlowDraftType = 'flow' | 'draft';

export type ProportionsImageSettingType = 'contain' | 'cover' | 'auto' | 'stretch';

export interface CellPaddingSettings {
  horizontal: number;
  vertical: number;
}

export interface ConnectedCellPaddingSettings extends CellPaddingSettings {
  isConnection: boolean;
}

export interface IdInterface {
  id: string;
}

export interface ModelIdInterface {
  modelId: string | null;
}

export interface FilterInfluencesInterface {
  filterInfluences: MapRecordType<boolean> | null;
}

export interface PageIdInterface {
  pageId: string;
}

export interface PositionConfigInterface {
  x: number;
  y: number;
}

export interface BoardPositionConfigInterface extends PositionConfigInterface, MaxMinSizeBoard, OptionStickingPoint {
  width: number;
  height: number;
}

export interface MaxMinSizeBoard {
  minHeight: number | null;
  maxHeight: number | null;
  minWidth: number | null;
  maxWidth: number | null;
}

export interface OptionStickingPoint {
  sticking: AlignmentInterface;
}

export interface GroupSettingsInterface {
  isGrouped: boolean;
  groupId: string | null;
  positionConfig: BoardPositionConfigInterface;
}

export interface GroupInterface {
  group: GroupSettingsInterface;
}

export interface PositionConfigSettingsInterface {
  positionConfig: BoardPositionConfigInterface;
}

export interface FilterInfluenceInterface {
  filterInfluences: MapRecordType<boolean> | null;
}

export interface SqlDataInterface {
  sqlData: SqlDataRequestInterface;
}

export interface SqlDataRequestInterface {
  incisionRequest: string;
  filterAndGroupRequest: string;
}

export interface LimitInterface {
  limit: LimitSettingInterface;
}

export interface IsMultipleInterface {
  isMultipleMode: boolean | null;
}

export interface OverflowInterface {
  overflowPosition: OverflowPositionInterface;
}

export interface SqlLimitInterface {
  to: number;
}

export interface StartAndEndInterface {
  start: number;
  end: number;
}

export interface PaddingsVisualisationInterface {
  topPadding: number;
  leftPadding: number;
  rightPadding: number;
  bottomPadding: number;
}

export type AutoEndManualType = 'auto' | 'manual';

export interface AutoOrManualInterface {
  type: AutoEndManualType;
  value: number;
}

export interface ProjectIdInterface {
  projectId: string;
}

export type ProjectIdWithType<T> = ProjectIdInterface & T;

export interface AbortSignalInterface {
  signal: AbortSignal;
}

export interface DisableDraggingInterface {
  disableDragging: boolean;
}

export interface HexColorInterface {
  hex: string;
}

export interface HlsColorInterface {
  h: number;
  s: number;
  l: number;
  opacity?: number;
}

export interface HexAndHLSColorInterface extends HexColorInterface, HlsColorInterface {
  name: string;
}

export type ColorEditType = keyof HexAndHLSColorInterface;

export type BorderPlacementType = 'inset' | 'outset';

export interface BorderStyleSettingsInterface extends IsActiveInterface {
  radius: number;
  width: number;
  style: BorderStyleType;
  color: ColorValuesByThemeType | null;
  borderPlacement: BorderPlacementType;
}

export interface BorderSettingsInterface {
  borderSettings: BorderStyleSettingsInterface;
}

export interface PaddingSettingsInterface {
  paddingSettings: IndentationInterface;
}

export interface ShadowColorSettings {
  shadowColor: ColorSettingsStateInterface;
  hover: ColorSettingsStateInterface;
  shadowColorBy: ColorByInterface;
}

export interface ShadowSettingInterface extends IsActiveInterface {
  blurRadius: number;
  offsetX: number;
  offsetY: number;
  shadowColorSettings: ShadowColorSettings;
}

export interface ShadowSettingsInterface {
  shadowSettings: ShadowSettingInterface;
}

export interface ShowBackgroundInterface {
  showBackground?: boolean;
}

export interface ViewFontSettingsInterface extends IsActiveInterface {
  fontFamily: string;
  fontWeight?: string;
  fontStyle?: string;
  fontUrl?: string;
  variants?: VariantsFontsProjectSettingsInterface | null;
}

export interface SizeOptionInterface {
  name: string;
  value: ContainerValuesEnum;
}

export interface AllSizeOptionsInterface {
  widthOption: SizeOptionInterface;
  heightOption: SizeOptionInterface;
}

export interface ContainerSettingsInterface
  extends PaddingSettingsInterface,
    DisableDraggingInterface,
    LocationInterface,
    Partial<AllSizeOptionsInterface> {
  fontFamilySettings: ViewFontSettingsInterface;
}

export interface BackgroundViewSettingsInterface {
  backgroundSettings: BackgroundSettingsInterface;
}

export interface LocationInterface {
  freeLocation: boolean;
}

export interface SqlRequestInterface {
  sqlRequest?: string;
}

export interface WhereQueryInterface {
  whereQuery?: string;
}

export interface LoadingInterface {
  loading: boolean;
}

export interface SelectInterface {
  name: string;
  value: string;
}

export interface FilterElementMode {
  filterElementMode: FilterElementModeEnum;
}

export enum FilterElementModeEnum {
  MANUAL = 'manual',
  DYNAMICALLY = 'dynamically',
}

export enum GroupEnum {
  OWNER = 'owner',
  READER = 'reader',
}

export interface DesignOptionInterface {
  value: 'px' | '%';
  label: number;
}

export interface DefaultUserInterface {
  id: string;
  title: string;
}

export interface UsersAndGroupInterface {
  id: string;
  name: string;
  entity: EntityType;
}

export type FontSettings = {
  fontFamily: string;
  fontWeight?: string;
  fontStyle?: string;
  fontUrl?: string;
};

export interface FontFamilyDataSettingsInterface extends IsActiveInterface, FontSettings {}

export interface FontFamilySettingsInterfaceInterface extends LoadingInterface {
  fontSettingsData: FontFamilyDataSettingsInterface;
}

export interface ColorDefaultSettingsInterface extends IsActiveInterface {
  color: ColorValuesByThemeType | null;
}

export type TopAndBottomType = 'top' | 'bottom';

export type RightAndLeftType = 'right' | 'left';

export type AbsolutePositionType = TopAndBottomType | RightAndLeftType;

export type ThemeColorType = 'dark' | 'light';

export type ByType = 'byDay' | 'byMonth' | 'byQuarter' | 'byWeek' | 'byToday' | 'byYear';

export type RoleType = 'admin' | 'developer' | 'analyst' | 'viewer';

export type AccessLevel = 'all' | 'private' | 'admin';

export type GroupType = 'owner' | 'reader';

export type EntityType = 'group' | 'user';

export type StatusType = 'success' | 'warning' | 'error' | 'pending' | 'aborted' | 'active';

export type ModeForm = 'add' | 'edit';

export type AvatarType = 'avatar' | 'screen';

export type ProjectVersionsStorageType = 'items' | 'time';
