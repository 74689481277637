import { configureStore } from '@reduxjs/toolkit';
import * as reducers from 'store/reducers';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { enableMapSet } from 'immer';
import loggerAllSteps from './middlewares/loggerAllSteps';
import { licenseMiddleware } from 'store/middlewares/licenseMiddleware';
import { initialLocalStorage, whiteListLocalStorage } from './constants';
import { mergeObjects } from 'utils/utils';

enableMapSet();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: whiteListLocalStorage,
  version: 1,
  migrate: async (state: any) => {
    return mergeObjects(initialLocalStorage, state) as typeof state;
  },
};

const rootReducer = combineReducers({
  ...reducers,
});

export type TState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(loggerAllSteps)
      .concat(licenseMiddleware),
});

const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useShallowEqualSelector = <TState = AppDispatch, TSelected = unknown>(
  selector: (state: TState) => TSelected,
): TSelected => useSelector(selector, shallowEqual);

export { store, persistor };
