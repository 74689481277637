import { createSlice, isAllOf, PayloadAction } from '@reduxjs/toolkit';
import { initialPagesStoreState } from 'store/reducers/projectPages/constants';
import { PageInterface } from 'store/reducers/projectPages/types';
import {
  loadFirstPageIdAction,
  loadPagesAction,
  loadPagesFromSnapshotAction,
  loadRLSUserAndGroupAction,
} from 'store/reducers/projectPages/actions';
import { IdInterface } from 'types/store';
import { setSliceFn } from 'constants/store';
import { uploadDashboardSettingsAction } from 'store/reducers/projectSettings/actions';
import isUndefined from 'lodash/isUndefined';

export const projectPagesSlice = createSlice({
  name: 'projectPages',
  initialState: initialPagesStoreState,
  reducers: {
    addNewPage: (state, { payload: data }: PayloadAction<PageInterface>) => {
      state.projectPages = [...state.projectPages, data];
    },
    updatePages: (state, { payload: page }: PayloadAction<PageInterface[]>) => {
      state.projectPages = page;
    },
    updatePageById: (state, { payload: { id, page } }: PayloadAction<{ page: Partial<PageInterface> } & IdInterface>) => {
      const indexPage = state.projectPages.findIndex((page) => page.id === id);

      if (!isUndefined(indexPage)) {
        state.projectPages[indexPage] = { ...state.projectPages[indexPage], ...page };
      }
    },
    deletePage: (state, { payload: id }: PayloadAction<string>) => {
      state.projectPages = state.projectPages.filter((page) => page.id !== id);
    },
    selectPage: (state, { payload: { id } }: PayloadAction<IdInterface>) => {
      state.activePageId = id;
    },
    setSlice: setSliceFn,
  },
  extraReducers: (builder) => {
    builder.addCase(loadPagesAction.pending, (state) => {
      state.pagesLoading = true;
      state.projectPages = [];
    });
    builder.addCase(loadPagesAction.fulfilled, (state, { payload: projectPages }) => {
      state.pagesLoading = false;
      state.projectPages = projectPages;
      state.serverStateOfProjectPages = projectPages;
    });

    builder.addCase(loadPagesAction.rejected, (state) => {
      state.pagesLoading = false;
      state.projectPages = [];
    });

    builder.addCase(loadPagesFromSnapshotAction.fulfilled, (state, { payload: projectPages }) => {
      state.projectPages = projectPages;
    });

    builder.addCase(loadFirstPageIdAction.pending, (state) => {
      state.firstPageId = null;
    });
    builder.addCase(loadFirstPageIdAction.fulfilled, (state, { payload: firstPageId }) => {
      state.firstPageId = firstPageId;
    });

    builder.addCase(loadFirstPageIdAction.rejected, (state) => {
      state.firstPageId = null;
    });

    builder.addCase(loadRLSUserAndGroupAction.pending, (state) => {
      state.rlsUserGroupAnfGroup = [];
    });
    builder.addCase(loadRLSUserAndGroupAction.fulfilled, (state, { payload: firstPageId }) => {
      state.rlsUserGroupAnfGroup = firstPageId;
    });
    builder.addCase(loadRLSUserAndGroupAction.rejected, (state) => {
      state.rlsUserGroupAnfGroup = [];
    });

    builder.addMatcher(isAllOf(uploadDashboardSettingsAction.fulfilled), (state) => {
      if (state.serverStateOfProjectPages) {
        state.serverStateOfProjectPages = state.projectPages;
      }
    });
  },
});

export const { addNewPage, selectPage, updatePages, updatePageById, deletePage, setSlice } = projectPagesSlice.actions;

export default projectPagesSlice.reducer;
