import { BlockVisibilitySettingsStateInterface } from './types';
import {
  defaultModelTitle,
  fontSettingsTitle,
  gridSpacingTitle,
  isRealDataTitle,
  scaleOfPageTitle,
  showWorkSpaceTitle,
  viewModeTitle,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/SettingsTab/constants';
import { DefaultOpenListEnum } from 'types/global';

const defaultStateBlock = { isOpen: true };

export const initialBlockVisibilitySettingsState: BlockVisibilitySettingsStateInterface = {
  blockVisibility: {
    [viewModeTitle]: defaultStateBlock,
    [defaultModelTitle]: defaultStateBlock,
    [isRealDataTitle]: defaultStateBlock,
    [fontSettingsTitle]: defaultStateBlock,
    [showWorkSpaceTitle]: defaultStateBlock,
    [gridSpacingTitle]: defaultStateBlock,
    [scaleOfPageTitle]: defaultStateBlock,
  },
  blockingSoftwareUnits: {
    [DefaultOpenListEnum.PROJECT_MANAGER_FLOWS]: defaultStateBlock,
    [DefaultOpenListEnum.SIDEBAR_CONSOLE]: defaultStateBlock,
    [DefaultOpenListEnum.SQL_CONSOLE]: defaultStateBlock,
  },
  allMainContainersIsOpen: false,
};
