export interface SourceTablesItemType {
  title: string;
  type: string;
}

export interface SourceTablesInterface extends SourceTablesItemType {
  items: SourceTablesItemType[];
}

export interface LoadingScriptStateInterface {
  SQLScript: string;
  sourceTables: SourceTablesInterface[];
}

export type TablePreviewTypes = Record<string, string | number | null>;

export interface TableFieldsInterface {
  name: string;
  type: string;
  selected?: boolean;
}

export interface TableFieldsPreviewInterface {
  meta: TableFieldsInterface[];
  data: TablePreviewTypes[];
}

export interface InformationTableSourceInterface {
  sourceId: string;
  schemaName?: string;
  tableName: string;
  rowCount: number;
  driverType: string;
}

export interface TableSelectedInterface {
  schema?: string;
  table: string;
  columns: { name: string; type: string }[];
}

/* Action Types */
export enum LoadingScriptActionsTypes {
  GET_SQL_SCRIPT = 'GET_SQL_SCRIPT',
  RUN_SQL_SCRIPT = 'RUN_SQL_SCRIPT',
  LOADING_TABLES = 'LOADING_TABLES',
  LOADING_PREVIEW_TABLES = 'LOADING_PREVIEW_TABLES',
  CREATE_TABLES_SCRIPT = 'CREATE_TABLES_SCRIPT',
  LOAD_SOURCE_FILE_STATUS = 'LOAD_SOURCE_FILE_STATUS',
  UPLOAD_SQL_SCRIPT = 'UPLOAD_SQL_SCRIPT',
  LOAD_COPY_URL_SCRIPT = 'LOAD_COPY_URL_SCRIPT',
  CLEAR_LOADING_SCRIPT = 'CLEAR_LOADING_SCRIPT',
  CLEAR_SOURCE_TABLES = 'CLEAR_SOURCE_TABLES',
}

/* Payload Interfaces */
export interface FieldsPreviewTablesPayload {
  sourceId: string;
  tableName: string;
  schemaName?: string;
}

export type InformationTableSourcePayload = FieldsPreviewTablesPayload;

export interface CreateTablesScriptActionPayload {
  projectId: string;
  sourceId: string;
  tables: TableSelectedInterface[];
}

export interface UploadSQLScriptPayload {
  projectId: string;
  script: string;
}
