import { TState } from 'store/index';
import { ISelectField } from 'interface';
import { defaultPageSizes } from 'store/reducers/projectPages/constants';
import { getMapObject } from 'utils/utils';
import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import isEqual from 'lodash/isEqual';
import { IdInterface } from '../../../types/store';

export const getPagesStore = createSelector(getState, (state) => state.projectPages);

export const getPages = createSelector(getPagesStore, (state) => state.projectPages);

export const getRLSUserGroupAnfGroup = createSelector(getPagesStore, (state) => state.rlsUserGroupAnfGroup);

export const getServerStateOfProjectPages = createSelector(getPagesStore, (state) => state.serverStateOfProjectPages);
export const getHasChangesOfPages = createSelector([getServerStateOfProjectPages, getPages], (serverState, currentState) =>
  serverState === null ? false : !isEqual(serverState || [], currentState),
);
export const getPagesLoading = createSelector(getPagesStore, (state) => state.pagesLoading);

export const getMapPages = createSelector(getPages, (pages) => getMapObject(pages, 'id'));

export const getPageIds = createSelector(getPages, (pages) => pages.map(({ id }) => id));

export const getPageById = ({ id }: IdInterface) => createSelector(getPages, (pages) => pages.find((page) => page.id === id));

export const getFirstPageId = createSelector(getPagesStore, (state) => state.firstPageId);

export const getActivePageId = createSelector(getPagesStore, (state) => state.activePageId);

export const getActivePage = createSelector([getActivePageId, getPages], (activePageId, pages) =>
  pages.find(({ id }) => id === activePageId),
);

export const getActivePageSize = createSelector(
  getActivePage,
  (activePage) => activePage?.boardSettings?.sizes || defaultPageSizes,
);

export const getPageSizeById = ({ id }: IdInterface) =>
  createSelector(getPageById({ id }), (activePage) => activePage?.boardSettings?.sizes || defaultPageSizes);

export const getActivePageColor = createSelector(getActivePage, (activePage) => activePage?.color);

export const getActivePageBackgroundImage = createSelector(getActivePage, (activePage) => activePage?.image);

export const getPagesAtSelectItems: (state: TState) => ISelectField[] = createSelector(getPages, (pages) =>
  pages.map(({ name, id }) => ({ name, value: id })),
);
