import Fuse from 'fuse.js';
import { CSSProperties, FC as FCReact, MouseEvent, PropsWithChildren } from 'react';
import { DefaultDataSettingsInterface } from 'store/reducers/visualisations/types';

export interface AbortablePromise<T> extends Promise<T> {
  abort: () => void;
}

export type ValueOf<T> = T[keyof T];

export type ReactFCNoop = () => JSX.Element | null;
export type ReactFC<Props> = (props: Props) => JSX.Element | null;

export type FC<T> = FCReact<PropsWithChildren<T>>;

export type ReactChildrenType = PropsWithChildren['children'];

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NonUndefined<T> = T extends undefined ? never : T;

export type NonNullableObject<T> = {
  [P in keyof T]: Exclude<T[P], null>;
};

export type NonNull<T> = T extends null ? never : T;

export type NullableValue = string | number | null;

export type StrictKeys<T, K extends keyof T = keyof T> = Omit<T, K> & { [SubKey in K]: NonNull<T[SubKey]> };

export type NoopType = () => void;
export type NoopValueType<T> = (value: T) => void;
export type NoopValueExitType<ValueT, ExitT> = (value: ValueT) => ExitT;
export type NoopPromiseValueType<T> = (value: T) => Promise<void>;

export type MapRecordType<T> = Record<string, T | undefined>;

export type TimerIdType = NodeJS.Timeout | null;

export interface OnValueChange<Value> {
  value: Value;
  onChange: NoopValueType<Value>;
}

export interface EditableItemInterface<T> {
  value: string;
  key: T;
}

export interface WizardStepComponentInterface<WizardItemType extends string | number | symbol> {
  onNext?: NoopValueType<WizardItemType>;
  onBack?: NoopType;
  onClose?: NoopType;
  disabledOnBack?: boolean;
  projectId?: string;
}

export interface WizardItemType<WizardItemType extends string | number | symbol> {
  Component?: (props: WizardStepComponentInterface<WizardItemType>) => JSX.Element;
  title?: string;
  width?: string;
  maxHeight?: string;
  height?: string;
}

export type WizardsStepDataInterface<WizardItem extends string | number | symbol> = Record<
  WizardItem,
  WizardItemType<WizardItem> | undefined
>;

export interface IsLoadingInterface {
  isLoading: boolean;
}

export interface IsErrorInterface {
  isError?: string | null;
}

export interface SortingButtonsInterface {
  onClick?: (event: MouseEvent<SVGSVGElement>) => void;
  style?: CSSProperties;
}

export interface IsInfluenceEditingInterface {
  isInfluenceEditing: boolean;
}

export interface ErrorMessageInterface {
  message?: string;
}

export interface OnInfluenceChangeInterface {
  //TODO: Убрать any
  onInfluenceChange: (data: {
    visualizationInfluences: any;
    filterInfluences: MapRecordType<boolean>;
    isVisualization: boolean;
  }) => void;
}

export interface ActiveElementDataInterface {
  activeElementData: any;
}

export type GetFlatFunctionType<FlatListItem, OriginListItem> = (data: OriginListItem[]) => FlatListItem[];

export type GetOriginListFromFuseResultFunctionType<FlatListItem, OriginListItem> = (
  result: Fuse.FuseResult<FlatListItem>[],
  originData: OriginListItem[],
) => OriginListItem[];

export type SearchEngineLifecycleFunctionType<OriginListItem> = (result: OriginListItem[], searchString: string) => void;

export interface DefaultDataSettingsProps {
  dataSettings: DefaultDataSettingsInterface;
}

export enum DefaultOpenListEnum {
  PROJECT_MANAGER_FLOWS = 'projectManagerFlows',
  SIDEBAR_CONSOLE = 'sidebarConsole',
  SQL_CONSOLE = 'sqlConsole',
}
