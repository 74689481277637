import { createSelector } from 'reselect';
import { getState } from 'store/utils';
import { DefaultOpenListEnum } from 'types/global';

export const getBlockVisibilitySettingsStore = createSelector(getState, (state) => state.blockVisibilitySettings);

export const getBlockVisibilityStore = createSelector(getBlockVisibilitySettingsStore, (state) => state.blockVisibility);

export const getBlockVisibilityByName = (name: string) =>
  createSelector(getBlockVisibilitySettingsStore, (state) => state.blockVisibility?.[name]);

export const getBlockSoftwareUnits = createSelector(getBlockVisibilitySettingsStore, (state) => state.blockingSoftwareUnits);

export const getBlockSoftwareUnitByName = (name: DefaultOpenListEnum) =>
  createSelector(getBlockVisibilitySettingsStore, (state) => state.blockingSoftwareUnits?.[name]);

export const getAllMainContainersIsOpen = createSelector(
  getBlockVisibilitySettingsStore,
  (state) => state.allMainContainersIsOpen,
);
