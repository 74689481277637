import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { HexAndHLSColorInterface } from 'types/store';
import { ActiveColorInterface } from '../PaletteColorSettings/types';
import { useMemo } from 'react';

export const useActiveColorData = (
  palette: PaletteItemInterface,
  activeColor: ActiveColorInterface,
): HexAndHLSColorInterface | null =>
  useMemo(() => {
    if (activeColor.activeColorIds?.colorId) {
      const { groupId, colorId } = activeColor.activeColorIds;
      const colorData = palette.groups.find((group) => group.id === groupId)?.colors.find((color) => color.id === colorId);

      if (colorData) {
        const { h, s, l, opacity, hex, name } = colorData;
        return { h, s, l, opacity, hex, name };
      }
    }
    return null;
  }, [activeColor, palette.groups]);
