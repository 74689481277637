import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { SettingsRadio } from 'modules/settingsContainer/SettingsRadio';
import React from 'react';
import { OnValueChange } from 'types/global';
import { ProportionsImageSettingType } from 'types/store';
import { getActiveRadioValue } from 'modules/settingsContainer/SettingsRadio/constants';
import { titleProportionsOptions } from './constants';

type ProportionsImageSettingProps = OnValueChange<ProportionsImageSettingType>;

export const ProportionsImageSettings = ({ onChange, value }: ProportionsImageSettingProps) => {
  const activeValue = getActiveRadioValue(titleProportionsOptions, value);

  return (
    <GroupContainerSettings titleText="Пропорции">
      <SettingsRadio onChange={({ value }) => onChange(value)} activeValue={activeValue} options={titleProportionsOptions} />
    </GroupContainerSettings>
  );
};
