import { ParentsVariableInterface, VariableExpandedStatusType, VariableStatusType } from 'store/reducers/projectPages/types';
import { VariableExpandedStatus, VariableStatus } from 'store/reducers/projectPages/constants';
import { SelectItemInterface } from 'modules/ui/Select';
import { IdInterface } from 'types/store';

export const optionsVariablePage: SelectItemInterface<VariableStatusType>[] = [
  { name: 'true', value: VariableStatus.TRUE },
  { name: 'false', value: VariableStatus.FALSE },
];

export const optionsVariableExpandedPage: SelectItemInterface<VariableExpandedStatusType>[] = [
  ...optionsVariablePage,
  { name: 'invert', value: VariableExpandedStatus.INVERT },
];

export const optionsVariableFunc = ({ values }: { values: ParentsVariableInterface[] }): (SelectItemInterface & IdInterface)[] =>
  values.map(({ id, name }) => ({ name, value: name, id }));
