import { MainContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PalettesContent } from './PalettesContent';
import { PalettesToolbar } from './PalettesToolbar';
import { PalettesHeader } from './PalettesHeader';
import { AIColorGenerationSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/AIColorGenerationSettings';
import { usePalettesTab } from './hooks/usePalettesTab';

export const PalettesTab = () => {
  const {
    activePalette,
    activeThemeId,
    palettes,
    synchronizationGroupMode,
    toggleSynchronizationGroupMode,
    activeColor,
    setActiveColor,
    fileInputRef,
    isOpen,
    onOpen,
    onClose,
    activeColorData,
    handlePaletteChange,
    handleCopyPalette,
    handleDeletePalette,
    handleOpenPaletteModal,
    handleDeleteGroup,
    handleAddGroup,
    handleCopyGroup,
    handleMovePaletteGroup,
    handleGroupNameChange,
    handleDeleteColor,
    handleAddColor,
    handleColorChange,
    handleColorsChange,
    handleFileButtonClick,
    handleImportClick,
    handleExportClick,
  } = usePalettesTab();

  if (!activePalette || !activeThemeId) {
    return (
      <MainContainerSettings>
        <FlexContainer height="40px" />
      </MainContainerSettings>
    );
  }

  return (
    <>
      <FlexContainer
        width="100%"
        position="fixed"
        flexDirection="column"
        backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}
        zIndex="3"
      >
        <PalettesHeader
          palettes={palettes}
          activePalette={activePalette}
          onAddPaletteClick={() => handleOpenPaletteModal('add')}
          onRenamePaletteClick={() => handleOpenPaletteModal('edit', activePalette.name)}
          onCopyPaletteClick={handleCopyPalette}
          onDeletePaletteClick={handleDeletePalette}
          onChangePalette={handlePaletteChange}
        />

        <PalettesToolbar
          activeColorData={activeColorData}
          onAddGroup={handleAddGroup}
          onOpenAI={onOpen}
          isOpenAI={isOpen}
          onCloseAI={onClose}
          synchronizationGroupMode={synchronizationGroupMode}
          onToggleSynchronizationGroupMode={toggleSynchronizationGroupMode}
          onFileButtonClick={handleFileButtonClick}
          fileInputRef={fileInputRef}
          onImportFileChange={handleImportClick}
          onExportClick={handleExportClick}
        />
      </FlexContainer>

      <PalettesContent
        activePalette={activePalette}
        activeColor={activeColor}
        setActiveColor={setActiveColor}
        synchronizationGroupMode={synchronizationGroupMode}
        onDeleteColor={handleDeleteColor}
        onDeleteGroup={handleDeleteGroup}
        onAddColor={handleAddColor}
        onCopyGroup={handleCopyGroup}
        onColorChange={handleColorChange}
        onColorsChange={handleColorsChange}
        onGroupNameChange={handleGroupNameChange}
        onMovePaletteGroup={handleMovePaletteGroup}
      />

      <AIColorGenerationSettings isOpen={isOpen} onClose={onClose} />
    </>
  );
};
