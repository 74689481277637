import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { NumberPropertiesBlock } from 'modules/settingsContainer/common/NumberPropertiesBlock';
import { pxUnit } from 'constants/global';
import { FlexContainer } from 'styles/FlexContainer';
import { useSelector } from 'react-redux';
import { getActivePage } from 'store/reducers/projectPages/getters';
import { ParentsVariableInterface, UpdatePageByIdPayload, VariableStatusType } from 'store/reducers/projectPages/types';
import {
  addBooleanVariableToPageByIdAction,
  deleteVariableToPageByIdAction,
  updatePageByIdAction,
  updateVariableToPageByIdAction,
} from 'store/reducers/projectPages/actions';
import { useAppDispatch } from 'store';
import { showWorkSpaceTitle } from '../SettingsTab/constants';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { NameSettings } from 'modules/settingsContainer/common/data/NameSettings';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { Select } from 'modules/ui';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { optionsVariablePage } from './constants';
import { ProportionsImageSettings } from 'modules/settingsContainer/common/ProportionsImageSettings';

export const SettingsPageTab = () => {
  const dispatch = useAppDispatch();
  const activePage = useSelector(getActivePage);

  const { controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected } = useDataSettingsMetric('');

  if (!activePage) {
    return null;
  }

  const onPageChange = (params: UpdatePageByIdPayload) => dispatch(updatePageByIdAction(params));

  const {
    id,
    boardSettings: {
      sizes: { width, height },
    },
    isShowWorkSpace,
    color,
    image,
    variables,
  } = activePage;

  const variablesRender: MetricsRenderType<ParentsVariableInterface> = ({ metrics }) => {
    const allNamesMetrics = metrics.map(({ name }) => name);

    return (
      <>
        {metrics.map(({ id, name }) => {
          const updateName = (newName: string) =>
            dispatch(
              updateVariableToPageByIdAction({
                id: activePage.id,
                variableId: id,
                data: { name: newName },
              }),
            );

          return (
            <SettingsFieldEntry
              key={id}
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              isActive={id === controls.activeMetricId?.value}
              fieldValue={name}
              onFieldChange={updateName}
              disabledViewColorPicker
              disabledViewChildren
              allNames={allNamesMetrics}
            />
          );
        })}
      </>
    );
  };

  const fieldSettingsRender: FieldSettingsRenderType<ParentsVariableInterface> = ({ metric: { id, status } }) => {
    const updateStatus = (newStatus: VariableStatusType) =>
      dispatch(
        updateVariableToPageByIdAction({
          id: activePage.id,
          variableId: id,
          data: { status: newStatus },
        }),
      );

    return (
      <FlexContainer padding="10px">
        <Select
          title="Текущее значение"
          needBackground={false}
          name="value"
          options={optionsVariablePage}
          width="100%"
          value={status}
          onChange={updateStatus}
        />
      </FlexContainer>
    );
  };

  return (
    <>
      <MainContainerSettings titleText="Размеры страницы">
        <GroupContainerSettings>
          <ElementContainerSettings>
            <FlexContainer gap="50px">
              <NumberPropertiesBlock
                properties="Ш"
                unit={pxUnit}
                name="Ш"
                value={width}
                onChange={(newWidth) =>
                  onPageChange({
                    id,
                    page: { ...activePage, boardSettings: { sizes: { width: newWidth, height } } },
                  })
                }
              />
              <NumberPropertiesBlock
                properties="В"
                unit={pxUnit}
                name="В"
                value={height}
                onChange={(newHeight) =>
                  onPageChange({
                    id,
                    page: { ...activePage, boardSettings: { sizes: { width, height: newHeight } } },
                  })
                }
              />
            </FlexContainer>
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
      <MainContainerSettings
        titleText={showWorkSpaceTitle}
        switcherState={isShowWorkSpace}
        switcherChange={() =>
          onPageChange({
            id,
            page: { ...activePage, isShowWorkSpace: !isShowWorkSpace },
          })
        }
      />
      <MainContainerSettings
        titleText="Изменить цвет страницы"
        switcherState={!!color?.isActive}
        switcherChange={() =>
          onPageChange({
            id,
            page: { ...activePage, color: { ...color, isActive: !color?.isActive } },
          })
        }
      >
        <GroupContainerSettings>
          <ElementContainerSettings>
            <FlexContainer gap="8px">
              <PrimaryTextSpan>Цвет</PrimaryTextSpan>
              <ColorPicker
                Activator={CircleActivator}
                closeOnClick
                type="color"
                onChange={(value) =>
                  onPageChange({
                    id,
                    page: { ...activePage, color: { isActive: !!color?.isActive, color: value } },
                  })
                }
                value={color?.color}
              />
            </FlexContainer>
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
      <MainContainerSettings titleText="Изображение">
        <FlexContainer gap="16px" flexDirection="column">
          <GroupContainerSettings>
            <ElementContainerSettings>
              <FlexContainer gap="8px" alignItems="center" width="100%">
                <PrimaryTextSpan>Ссылка:</PrimaryTextSpan>
                <NameSettings
                  value={image.link}
                  onChange={(newImage) =>
                    onPageChange({
                      id,
                      page: { ...activePage, image: { ...image, link: newImage } },
                    })
                  }
                />
              </FlexContainer>
            </ElementContainerSettings>
          </GroupContainerSettings>

          <ProportionsImageSettings
            value={image.proportionsImageType}
            onChange={(value) =>
              onPageChange({
                id,
                page: { ...activePage, image: { ...image, proportionsImageType: value } },
              })
            }
          />
        </FlexContainer>
      </MainContainerSettings>

      <MetricSettings
        titleText="Триггеры страницы"
        addButtonText="Создать триггер"
        metricRender={variablesRender}
        fieldSettingsRender={fieldSettingsRender}
        metrics={variables}
        onAdd={() => dispatch(addBooleanVariableToPageByIdAction({ id }))}
        onDelete={(variableId) =>
          variableId &&
          dispatch(
            deleteVariableToPageByIdAction({
              id,
              variableId: variableId,
            }),
          )
        }
        controls={controls}
      />
    </>
  );
};
