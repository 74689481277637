import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { addStyleBufferAction, pasteStyleBufferAction } from 'store/reducers/board/actions';
import { getActiveBoardElementFunc } from 'store/reducers/board/constants';
import { getActiveBoardElements, getStyleBuffer } from 'store/reducers/board/getters';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';

export const useStyleBuffer = () => {
  const dispatch = useAppDispatch();

  const styleBuffer = useSelector(getStyleBuffer);
  const visualizationData = useSelector(getActiveVisualisationSettings)?.viewSettings;
  const activeWidgetsIds = useSelector(getActiveBoardElements);

  const activeWidgetId = getActiveBoardElementFunc({
    activeElements: activeWidgetsIds,
  });

  const onPasteStyleBuffer = () => {
    dispatch(pasteStyleBufferAction());
  };

  const onCopyStyleBuffer = () => {
    dispatch(addStyleBufferAction());
  };

  const disabledStyleCopy = activeWidgetsIds ? activeWidgetsIds.length > 1 : false;

  return {
    styleBuffer,
    visualizationData,
    activeWidgetsIds,
    activeWidgetId,
    onPasteStyleBuffer,
    onCopyStyleBuffer,
    disabledStyleCopy,
  };
};
