import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

/* TODO: Move all interface same as selected, active, checked etc. to types/global.ts */

interface RadioItemProps {
  selected: boolean;
  disabled?: boolean;
}

export const RadioItem = styled.p<RadioItemProps>`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: var(${ColorVarsEnum.Level_1});
  margin: 0;
  cursor: pointer;
  transition: color 100ms ease-in-out;
  white-space: nowrap;

  ${({ selected }) =>
    selected &&
    css`
      color: var(${ColorVarsEnum.Accent});
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(${ColorVarsEnum.Level_3});
      cursor: not-allowed;
    `};
`;
