import { DeleteButtonWrapper } from 'modules/ui/buttons/AddColorButton/styles';
import { NoopType, ReactChildrenType } from 'types/global';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { DeleteIcon } from 'assets/icons/withContainer';
import React from 'react';
import { PickerItemWrapper, type PickerItemWrapperProps } from './styles';

interface PickerWrapperProps extends PickerItemWrapperProps {
  name?: string | number;
  minHeightColorByPickerWrapper?: string;
  onDelete?: NoopType;
  children: ReactChildrenType;
  isRemoveDeleteIcon?: boolean;
}

export const PickerWrapper = ({
  name,
  onDelete,
  children,
  isRemoveDeleteIcon,
  minHeightColorByPickerWrapper,
  pickerItemDirection,
}: PickerWrapperProps) => {
  return (
    <PickerItemWrapper pickerItemDirection={pickerItemDirection} minHeight={minHeightColorByPickerWrapper}>
      {children}
      {name && (
        <PrimaryTextSpan lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
          {name}
        </PrimaryTextSpan>
      )}
      {!isRemoveDeleteIcon && (
        <DeleteButtonWrapper onClick={onDelete}>
          <IconWrapper
            hoverColorVar={ColorVarsEnum.Level_3}
            colorVar={ColorVarsEnum.Level_3}
            iconWidth="15px"
            iconHeight="13px"
            Icon={DeleteIcon}
          />
        </DeleteButtonWrapper>
      )}
    </PickerItemWrapper>
  );
};
