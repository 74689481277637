import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import React, { ChangeEvent, RefObject } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { AddFolder, AIColorGeneration, LinkIcon } from 'assets/icons/withContainer';
import { ExportIcon, ImportIcon } from 'assets/icons/navigations';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import SubSection from 'shared/ui/subSection';
import { HexAndHLSColorInterface } from 'types/store';
import { NoopType, NoopValueType } from 'types/global';

interface PalettesToolbarProps {
  activeColorData: HexAndHLSColorInterface | null;
  onAddGroup: (params: { color: HexAndHLSColorInterface | null }) => void;
  onOpenAI: NoopType;
  isOpenAI: boolean;
  onCloseAI: NoopType;
  synchronizationGroupMode: boolean;
  onToggleSynchronizationGroupMode: NoopType;
  onFileButtonClick: NoopType;
  fileInputRef: RefObject<HTMLInputElement>;
  onImportFileChange: NoopValueType<ChangeEvent<HTMLInputElement>>;
  onExportClick: NoopType;
}

export const PalettesToolbar: React.FC<PalettesToolbarProps> = ({
  activeColorData,
  onAddGroup,
  onOpenAI,
  synchronizationGroupMode,
  onToggleSynchronizationGroupMode,
  onFileButtonClick,
  fileInputRef,
  onImportFileChange,
  onExportClick,
}) => {
  return (
    <MainContainerSettings>
      <SubSection>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <GroupContainerSettings width="100%">
              <ElementContainerSettings width="100%" alignItems="center" justifyContent="space-between">
                <FlexContainer width="100%" justifyContent="space-between" alignItems="center">
                  <FlexContainer gap="3px" alignItems="center">
                    <TooltipIconButton
                      iconSize="big"
                      heightSize="normal"
                      title="Генерация цвета с помощью ИИ"
                      leftIcon={<AIColorGeneration />}
                      onClick={onOpenAI}
                    />
                    <TooltipIconButton
                      iconSize="big"
                      heightSize="normal"
                      title="Создать группу"
                      leftIcon={<AddFolder />}
                      onClick={() => onAddGroup({ color: activeColorData })}
                    />
                    <FlexContainer borderRight={`1px solid var(${ColorVarsEnum.Level_5})`} height="24px" />
                    <TooltipIconButton
                      iconSize="big"
                      heightSize="normal"
                      title="Импортировать"
                      leftIcon={<ImportIcon />}
                      onClick={onFileButtonClick}
                    />
                    <input
                      type="file"
                      accept=".json"
                      ref={fileInputRef}
                      onChange={onImportFileChange}
                      style={{ display: 'none' }}
                    />
                    <TooltipIconButton
                      iconSize="big"
                      heightSize="normal"
                      title="Экспортировать"
                      leftIcon={<ExportIcon />}
                      onClick={onExportClick}
                    />
                  </FlexContainer>

                  <FlexContainer gap="3px" alignItems="center">
                    <TooltipIconButton
                      title="Групповое редактирование"
                      leftIcon={<LinkIcon />}
                      selected={synchronizationGroupMode}
                      onClick={onToggleSynchronizationGroupMode}
                    />
                  </FlexContainer>
                </FlexContainer>
              </ElementContainerSettings>
            </GroupContainerSettings>
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
    </MainContainerSettings>
  );
};
