import { PayloadAction } from '@reduxjs/toolkit';
import { defaultContainerSettings } from 'constants/store';
import {
  AddNewFilterPayload,
  CornerRoundingRadiusInterface,
  DateFilterDataInterface,
  DefaultSelectedValuesInterface,
  ElementDesignButtonsSettingsInterface,
  EnabledFilterDataType,
  EnabledFilterInterface,
  FilterDataType,
  FilterInterface,
  FilterNameSettingsInterface,
  FilterStateInterface,
  FilterType,
  SingleFilterDataInterface,
  SingleFilterSettingsInterface,
  UpdateFilterPayload,
  ViewSettingsDataInterface,
} from 'store/reducers/filters/types';
import { ColorDefaultSettingsInterface, FilterElementModeEnum, IdInterface, ViewFontSettingsInterface } from 'types/store';
import { OverflowValueEnum } from '../visualisations/types';
import {
  defaultProperties,
  defaultStyleContainerSettings,
  defaultVariablesSettings,
  initialPositionConfig,
} from '../visualisations/constants';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

export const initialFilterStoreState: FilterStateInterface = {
  filtersData: {
    filtersValues: {},
  },
  filters: {},
  serverStateOfFilters: null,
  filtersByPages: {},
  filtersByProject: {},
  enabledFilters: {},
  serverStateOfEnabledFilters: null,
  enabledFiltersByPages: {},
  filtersLoading: false,
  filtersValuesLoadingList: {},
  filtersErrorsList: {},
  enabledFiltersLoadingList: {},
  alreadyLoadedContent: {
    filters: new Set<string>(),
    enabledFilters: new Set<string>(),
  },
};

const defaultSingleFilterSettings: SingleFilterSettingsInterface = {
  paddings: { horizontal: 30, vertical: 10, isConnection: false },
};

export const defaultFllColorSettings: ColorDefaultSettingsInterface = {
  isActive: false,
  color: null,
};

const defaultCornerRoundingRadius: CornerRoundingRadiusInterface = {
  isActive: false,
  all: 0,
  leftTop: 0,
  leftBottom: 0,
  rightBottom: 0,
  rightTop: 0,
};

export const defaultFontFamilySettings: ViewFontSettingsInterface = {
  isActive: false,
  fontFamily: '',
  fontStyle: '',
  fontUrl: '',
  fontWeight: '',
  variants: null,
};

const defaultElementDesignSettings: ElementDesignButtonsSettingsInterface = {
  fillColor: defaultFllColorSettings,
  borderSettings: {
    isActive: false,
    radius: 8,
    width: 1,
    style: 'solid',
    color: null,
    borderPlacement: 'outset',
  },
  cornerRoundingRadius: defaultCornerRoundingRadius,
  textPropertiesSettings: {
    ...defaultProperties,
    fontSize: 18,
    lineHeight: 150,
  },
  borderLineStyle: {
    isActive: false,
    cornerRoundingRadius: defaultCornerRoundingRadius,
    position: 'bottom',
    widthSize: {
      type: 'auto',
      value: 30,
    },
    thickness: 4,
    fontColor: null,
  },
};

const defaultActiveElementDesignSettings: ElementDesignButtonsSettingsInterface = {
  fillColor: defaultFllColorSettings,
  borderSettings: {
    isActive: false,
    radius: 8,
    width: 1,
    style: 'solid',
    color: null,
    borderPlacement: 'outset',
  },
  cornerRoundingRadius: defaultCornerRoundingRadius,
  textPropertiesSettings: {
    ...defaultProperties,
    fontSize: 18,
    lineHeight: 150,
  },
  borderLineStyle: {
    isActive: true,
    cornerRoundingRadius: defaultCornerRoundingRadius,
    position: 'bottom',
    widthSize: {
      type: 'auto',
      value: 30,
    },
    thickness: 4,
    fontColor: null,
  },
};

const defaultViewSettingsSingleFilter: ViewSettingsDataInterface = {
  elementDesignSettingsData: {
    elementDesignSettings: {
      defaultState: defaultElementDesignSettings,
      active: defaultActiveElementDesignSettings,
      blocked: defaultElementDesignSettings,
    },
    fontFamilySettings: defaultFontFamilySettings,
    paddingSettings: {
      unsetPaddings: true,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    sizeButtonsSetting: {
      isActive: false,
      height: 70,
      width: 150,
    },
    stateButtonType: 'defaultState',
  },
  buttonSettings: {
    chevronLocations: 'defaultLocations',
    textPropertiesSettings: {
      ...defaultProperties,
      fontSize: 18,
      lineHeight: 150,
    },
  },
};

const defaultSingleFilterData: SingleFilterDataInterface = { selectedData: [] };

const defaultEnabledDateFilterData: DateFilterDataInterface = {
  byType: 'byDay',
  startDate: null,
  endDate: null,
};

export const getInitialFilterFn: <
  Type extends FilterType,
  FilterData = object,
  SettingsData = object,
  ViewSettingsData = object,
>(params: {
  type: Type;
  filterData: FilterData;
  settingsData: SettingsData;
  viewSettingsData: ViewSettingsData;
  isAlwaysOpen?: boolean;
  sortingStatus?: SortingValueEnum;
}) => (params: AddNewFilterPayload) => FilterInterface<Type, FilterData, SettingsData, ViewSettingsData> =
  ({ type, filterData, settingsData, viewSettingsData, isAlwaysOpen, sortingStatus }) =>
  ({ id, pageId, name }) => {
    return {
      ...filterData,
      ...settingsData,
      ...viewSettingsData,
      ...defaultContainerSettings,
      sortingStatus,
      isAlwaysOpen,
      id,
      pageId,
      type,
      positionConfig: {
        ...initialPositionConfig,
        width: 400,
        height: 60,
      },
      isRealData: false,
      isGlobal: false,
      isMultipleMode: null,
      isVisible: true,
      isBlock: false,
      filterInfluences: null,
      sqlData: {
        incisionRequest: '',
        filterAndGroupRequest: '',
      },
      limit: {
        value: 2000,
        isActive: false,
      },
      fictionalData: [],
      nameSettings: {
        name: 'Фильтр',
        fieldName: null,
        nameFromDatabase: false,
        filterElementMode: FilterElementModeEnum.MANUAL,
      },
      position: {
        positionVertically: 'flex-start',
        positionHorizontal: 'flex-start',
      },
      overflowPosition: { label: 'Последовательно', value: OverflowValueEnum.SEQUENTIALLY },
      modelId: null,
      group: {
        isGrouped: false,
        groupId: null,
        positionConfig: initialPositionConfig,
      },
      name: name || '',
      hint: {
        isShow: false,
        text: 'Подсказка',
        position: 'right',
      },
      styleContainerSettings: {
        ...defaultStyleContainerSettings,
        showBackground: false,
      },
      events: {
        variablesSettings: defaultVariablesSettings,
      },
    };
  };

export const initialFnByFilterType = {
  single: getInitialFilterFn({
    type: 'single',
    filterData: defaultSingleFilterData,
    settingsData: defaultSingleFilterSettings,
    sortingStatus: SortingValueEnum.ASC,
    viewSettingsData: defaultViewSettingsSingleFilter,
  }),
  multiple: getInitialFilterFn({
    type: 'multiple',
    filterData: {},
    settingsData: {},
    isAlwaysOpen: false,
    viewSettingsData: defaultViewSettingsSingleFilter,
  }),
  date: getInitialFilterFn({
    type: 'date',
    filterData: {},
    settingsData: {},
    viewSettingsData: defaultViewSettingsSingleFilter,
  }),
};

const filterMapTypes: Record<FilterType, undefined> = { single: undefined, date: undefined, multiple: undefined };
const filterTypes: FilterType[] = Object.keys(filterMapTypes) as FilterType[];

export const referenceFilters = filterTypes.reduce(
  (references, type) => ({
    ...references,
    [type]: initialFnByFilterType[type]({ id: 'reference', pageId: 'reference', name: '' }),
  }),
  {} as Record<FilterType, FilterDataType>,
);

export const getInitialEnabledFilterFn: <
  FilterType,
  SelectedValues extends { selectedValues: unknown } = DefaultSelectedValuesInterface,
>(params: {
  type: FilterType;
  selectedValues: SelectedValues;
}) => (params: AddNewFilterPayload) => EnabledFilterInterface<FilterType, SelectedValues> =
  ({ type, selectedValues }) =>
  ({ id, pageId }) => {
    return {
      id,
      pageId,
      type,
      ...selectedValues,
      isGlobal: false,
      isRealData: false,
      nameSettings: {
        fieldName: null,
        name: 'reference',
        nameFromDatabase: false,
        filterElementMode: FilterElementModeEnum.MANUAL,
      },
      link: 'filter',
      linkId: 'reference',
      modelId: null,
      filterInfluences: null,
      incisionRequest: '',
    };
  };

export const initialEnabledFnByFilterType = {
  single: getInitialEnabledFilterFn({
    type: 'single',
    selectedValues: { selectedValues: [] },
  }),
  multiple: getInitialEnabledFilterFn({
    type: 'multiple',
    selectedValues: { selectedValues: [] },
  }),
  date: getInitialEnabledFilterFn({
    type: 'date',
    selectedValues: { selectedValues: defaultEnabledDateFilterData },
  }),
};

export const referenceEnabledFilter = filterTypes.reduce(
  (references, type) => ({
    ...references,
    [type]: initialEnabledFnByFilterType[type]({ id: 'reference', pageId: 'reference', name: '' }),
  }),
  {} as Record<FilterType, EnabledFilterDataType>,
);

export const getFilterFieldName = ({ nameFromDatabase, name, fieldName }: FilterNameSettingsInterface['nameSettings']) =>
  nameFromDatabase ? fieldName || '' : name;

export const removeFilterByIdFunc = (state: FilterStateInterface, { payload: { id } }: PayloadAction<IdInterface>) => {
  const pageId = state.filters[id]?.pageId;

  if (pageId) {
    delete state.filters[id];
    delete state.filtersByProject[id];

    const setOfIds = state.filtersByPages[pageId] || new Set<string>();
    setOfIds.delete(id);

    state.filtersByPages = { ...state.filtersByPages, [pageId]: setOfIds };
  }
};

export const updateFilterSettingsFunc = (
  state: FilterStateInterface,
  { payload: { id, data } }: PayloadAction<UpdateFilterPayload>,
) => {
  const filter = state.filters[id];

  if (filter) {
    state.filters[id] = { ...filter, ...data };
    state.filtersByProject[id] = { ...filter, ...data };
  }
};
