import { useCallback, useState } from 'react';
import { NoopValueType } from 'types/global';
import { defaultActiveColor } from '../PaletteColorSettings/constants';
import { ActiveColorInterface } from '../PaletteColorSettings/types';

export const usePopoverController = (onChangeActiveColor: NoopValueType<ActiveColorInterface>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [activePopoverId, setActivePopoverId] = useState<string | null>(null);

  const closePopover = useCallback(() => {
    setActivePopoverId(null);
    setIsPopoverOpen(false);
    onChangeActiveColor(defaultActiveColor);
  }, [onChangeActiveColor]);

  const openPopover = useCallback(
    (
      popoverId: string,
      position: { top: number; left: number },
      newActiveColor: { activeColorIds: { groupId: string; colorId: string }; colorIndex: number },
    ) => {
      setActivePopoverId(popoverId);
      setAnchorPosition(position);
      setIsPopoverOpen(true);
      onChangeActiveColor(newActiveColor);
    },
    [onChangeActiveColor],
  );

  return { isPopoverOpen, anchorPosition, activePopoverId, closePopover, openPopover };
};
