import { isCopyHotkey, isDeleteHotkey, isLeftKeyz, isPasteHotkey, isRightKeyz } from 'modules/workspace/constans';
import { useEffect } from 'react';
import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { NoopType, NoopValueType } from 'types/global';
import { HexAndHLSColorInterface } from 'types/store';
import { isField, MoveToType } from 'utils/utils';
import { ActiveColorInterface, AddColorType } from '../PaletteColorSettings/types';

interface KeyboardParams {
  isSelectedColor: boolean;
  activeColorData: HexAndHLSColorInterface | null;
  activeGroupId: string;
  onAddColor: AddColorType;
  onLocalDeleteColor: NoopType;
  onChangeActiveColor: NoopValueType<ActiveColorInterface>;
  copiedColor: HexAndHLSColorInterface | null;
  palette: PaletteItemInterface;
  moveActiveColor: NoopValueType<MoveToType>;
  setCopiedColor: NoopValueType<HexAndHLSColorInterface | null>;
}

export const useKeyboardShortcuts = (params: KeyboardParams) => {
  const {
    isSelectedColor,
    activeColorData,
    activeGroupId,
    onAddColor,
    onLocalDeleteColor,
    onChangeActiveColor,
    copiedColor,
    palette,
    moveActiveColor,
    setCopiedColor,
  } = params;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const eTagName = (e.target as Element)?.tagName;

      if (!isSelectedColor || isField(eTagName)) return;

      if (isCopyHotkey(e)) {
        e.preventDefault();
        if (activeColorData) {
          setCopiedColor(activeColorData);
        }
      }

      if (isPasteHotkey(e)) {
        e.preventDefault();
        if (copiedColor) {
          onAddColor({ color: copiedColor, groupId: activeGroupId }).then((result) => {
            if (result) {
              const { groupId, colorId } = result;
              const colorGroup = palette.groups.find((g) => g.id === groupId);
              if (colorGroup) {
                const colorIndex = colorGroup.colors.length - 1;
                onChangeActiveColor({ activeColorIds: { groupId, colorId }, colorIndex });
              }
            }
          });
        }
      }

      if (isDeleteHotkey(e)) {
        e.preventDefault();
        onLocalDeleteColor();
      }

      if (isLeftKeyz(e)) {
        e.preventDefault();
        moveActiveColor('up');
      }

      if (isRightKeyz(e)) {
        e.preventDefault();
        moveActiveColor('down');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    isSelectedColor,
    activeColorData,
    activeGroupId,
    onAddColor,
    onLocalDeleteColor,
    onChangeActiveColor,
    copiedColor,
    palette,
    moveActiveColor,
    setCopiedColor,
  ]);
};
