import React from 'react';
import { StyledTooltip } from '../StyledTooltip';
import { ReactChildrenType } from 'types/global';
import { HintIcon } from 'modules/ui/Hint/styles';

interface HintProps {
  children: ReactChildrenType;
  disableInteractiveTooltipHint?: boolean;
}

export const Hint: React.FC<HintProps> = ({ children, disableInteractiveTooltipHint = true }) => (
  <StyledTooltip disableInteractive={disableInteractiveTooltipHint} title={children}>
    <HintIcon />
  </StyledTooltip>
);
