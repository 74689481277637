import {
  PageInterface,
  PagesStateInterface,
  ParentsVariableInterface,
  VariablesExpandedStatusTypes,
  VariablesStatusTypes,
  VariablesTypes,
} from 'store/reducers/projectPages/types';
import { IdInterface } from 'types/store';
import { v4 } from 'uuid';

export const initialPagesStoreState: PagesStateInterface = {
  projectPages: [],
  serverStateOfProjectPages: null,
  firstPageId: null,
  activePageId: '',
  pagesLoading: false,
  rlsUserGroupAnfGroup: [],
};

export const defaultPageSizes = {
  height: 1200,
  width: 1600,
};

export const getInitialPages: (params: IdInterface) => PageInterface = ({ id }) => ({
  id,
  name: 'Страница',
  isHidden: false,
  boardSettings: {
    sizes: defaultPageSizes,
    viewState: 'show',
  },
  isShowWorkSpace: true,
  color: {
    isActive: false,
    color: null,
  },
  accessSettings: {
    users: [],
    groups: [],
  },
  image: {
    link: '',
    proportionsImageType: 'auto',
  },
  variables: [],
});

export const VariableStatus: Record<Uppercase<VariablesStatusTypes>, VariablesStatusTypes> = {
  TRUE: 'true',
  FALSE: 'false',
};

export const VariableExpandedStatus: Record<Uppercase<VariablesExpandedStatusTypes>, VariablesExpandedStatusTypes> = {
  TRUE: 'true',
  FALSE: 'false',
  INVERT: 'invert',
};

export const VariablesType: Record<Uppercase<VariablesTypes>, VariablesTypes> = {
  STRING: 'string',
  BOOLEAN: 'boolean',
};

export const getInitialBooleanVariable = ({ name }: { name: string }): ParentsVariableInterface => ({
  id: v4(),
  name,
  type: VariablesType.BOOLEAN,
  status: VariableStatus.TRUE,
});

export const getInitialStringVariable = ({ name }: { name: string }): ParentsVariableInterface => ({
  id: v4(),
  name,
  type: VariablesType.STRING,
  status: '',
});
