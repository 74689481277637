import React, { FC, useCallback, useEffect, useId, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AceEditor, { IAceEditorProps } from 'react-ace';
import {
  addCompleter,
  keyWordCompleter,
  setCompleters,
  snippetCompleter,
  textCompleter,
} from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-pgsql';
import 'ace-builds/src-noconflict/theme-sqlserver';
import 'ace-builds/src-noconflict/theme-github_dark';
import { AceEditorThemeEnum, AdviceEditor, ExtendedRenderer } from 'modules/ui/SqlAutocomplete/types';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { getCompletionsByModelId } from 'store/reducers/models/getters';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';
import { getActiveThemeCodeEditorTheme } from 'store/reducers/themes/getters';
import { CSSProperties } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export interface SqlAutocompleteProps {
  value: string;
  handleChange?: (e: string) => void;
  height?: string;
  adviceEditor?: AdviceEditor[];
  autofocus?: boolean;
  onFocus?: IAceEditorProps['onFocus'];
  autoHeight?: boolean;
  readOnly?: boolean;
  style?: CSSProperties;
}

export const SqlAutocomplete: FC<SqlAutocompleteProps> = ({
  value = '',
  handleChange = () => {},
  height,
  adviceEditor = [],
  autofocus = true,
  onFocus,
  autoHeight = false,
  readOnly,
  style,
}) => {
  const id = useId();
  const editorRef = useRef<AceEditor | null>(null);
  const [editorHeight, setEditorHeight] = useState(height);

  const modelId = useSelector(getActiveVisualisationSettings)?.dataSettings?.modelId;
  const codeEditorTheme = useSelector(getActiveThemeCodeEditorTheme);
  const modelIdValue = useModelIdValue(modelId || '');
  const completions = useSelector(getCompletionsByModelId(modelIdValue));

  const adjustHeight = useCallback(() => {
    if (editorRef.current) {
      const editor = editorRef.current.editor;
      const newHeight = editor.getSession().getScreenLength() * editor.renderer.lineHeight;
      setEditorHeight(newHeight + 'px');
    }
  }, []);

  useEffect(() => {
    if (autoHeight) {
      adjustHeight();
      const editor = editorRef.current?.editor;
      editor?.getSession().on('change', adjustHeight);

      return () => {
        editor?.getSession().off('change', adjustHeight);
      };
    }
  }, [autoHeight, adjustHeight]);

  useEffect(() => {
    return () => {
      setCompleters([textCompleter, keyWordCompleter, snippetCompleter]);
    };
  }, []);

  useEffect(() => {
    addCompleter({
      getCompletions: (
        editor: any,
        session: any,
        pos: any,
        prefix: any,
        callback: (error: any, completions: AdviceEditor[]) => void,
      ) => {
        callback(null, [...adviceEditor, ...completions]);
      },
    });
  }, [adviceEditor, completions]);

  useEffect(() => {
    const editor = editorRef.current?.editor;
    if (readOnly && editor) {
      const renderer = editor.renderer as unknown as ExtendedRenderer;
      renderer.$cursorLayer.element.style.display = 'none';
    }
  }, [readOnly]);

  return (
    <AceEditor
      ref={editorRef}
      mode="pgsql"
      theme={codeEditorTheme === 'dark' ? AceEditorThemeEnum.GITHUB_DARK : AceEditorThemeEnum.SQLSERVER}
      name={id}
      readOnly={readOnly}
      width="100%"
      onChange={(text) => handleChange(text)}
      fontSize={14}
      height={editorHeight}
      showPrintMargin={false}
      showGutter={false}
      highlightActiveLine={false}
      value={value}
      onFocus={onFocus}
      setOptions={{
        readOnly,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        wrap: true,
        tabSize: 2,
        cursorStyle: 'ace',
        vScrollBarAlwaysVisible: false,
      }}
      style={{
        ...style,
        ...(readOnly && { color: `var(${ColorVarsEnum.Sub_Level_3}) !important` }),
        overflow: 'hidden',
      }}
      focus={autofocus}
    />
  );
};
