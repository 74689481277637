import { createAsyncThunk } from '@reduxjs/toolkit';
import { getActivePage, getActivePageId } from 'store/reducers/projectPages/getters';
import { TState } from 'store/index';
import { v4 } from 'uuid';
import {
  BoardActionsTypes,
  BufferType,
  LayersMap,
  MoveLayersPayload,
  MoveType,
  UpdateLayersPayload,
  UpdateMoveXYInterface,
} from 'store/reducers/board/types';
import {
  addStyleBuffer,
  addToLayer,
  deleteFromLayer,
  deleteLayer,
  setActiveBoardElement,
  setBuffer,
  setSlice,
  updateLayers,
} from 'store/reducers/board';
import { FilterDataType, GroupTypeEnum } from 'store/reducers/filters/types';
import {
  addVisualisationByData,
  removeVisualisationByIdAction,
  updatePositionConfigByIdAction,
  updateStyleViewSettingsByIdAction,
} from 'store/reducers/visualisations/actions';
import { addFilterByDataAction, removeFilterByIdAction, updateFilterAction } from 'store/reducers/filters/actions';
import {
  getActiveBoardElements,
  getBuffer,
  getLayerAlreadyLoaded,
  getLayerByPageId,
  getLayerIndexById,
  getStyleBuffer,
} from 'store/reducers/board/getters';
import { DefaultVisualisationOptionsType } from 'store/reducers/visualisations/types';
import { getActiveVisualisationSettings, getVisualisationById, getWidgetsByPageId } from 'store/reducers/visualisations/getters';
import { getActiveFilter, getFilterById, getFiltersByPageId } from 'store/reducers/filters/getters';
import { calculateGroupPositionConfig, deepMergeByReference, generateWidgetName, moveArrayItem } from 'utils/utils';
import { AxiosError } from 'axios';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { loadLayersByPageId } from 'store/reducers/board/api';
import {
  getActiveBoardElementFunc,
  getPositionConfigForPasteWidget,
  initialBoardStoreState,
} from 'store/reducers/board/constants';
import { BoardPositionConfigInterface, PageIdInterface, ProjectIdWithType } from 'types/store';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import { getProjectSettings } from 'store/reducers/projectSettings/getters';
import { BoardElementActivateEnum, GroupWidgetSettingsInterface } from 'store/reducers/groupsVisualisations/types';
import { getWidgetGroupById, getWidgetsGroupsByPageId } from 'store/reducers/groupsVisualisations/getters';
import {
  addWidgetGroupByDataAction,
  deleteFullWidgetGroupActionById,
  updateViewSettingsWidgetGroupAction,
  updateWidgetGroupAction,
} from 'store/reducers/groupsVisualisations/actions';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

const validateError = (err: AxiosError, rejectWithValue: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue(errorMessage);
};

export const setActiveBoardElementAction = createAsyncThunk(
  BoardActionsTypes.SET_ACTIVE_BOARD_ELEMENT,
  (activeBoardElement: { id: string | null; type?: BoardElementActivateEnum }, { dispatch }) => {
    dispatch(setActiveBoardElement(activeBoardElement));
  },
);

export const removeBoardElementByIdAction = createAsyncThunk(
  BoardActionsTypes.REMOVE_BOARD_ELEMENT_BY_ID,
  (id: string, { dispatch }) => {
    dispatch(removeFilterByIdAction(id));
    dispatch(removeVisualisationByIdAction(id));
    dispatch(deleteFullWidgetGroupActionById({ id }));
  },
);

export const removeBoardElementAction = createAsyncThunk(BoardActionsTypes.REMOVE_BOARD_ELEMENT, (_, { dispatch, getState }) => {
  const activeIds = getActiveBoardElements(getState() as TState);
  if (activeIds && activeIds.length > 0) {
    activeIds.forEach((id) => {
      dispatch(removeBoardElementByIdAction(id));
    });
  }
});

export const copyToBufferAction = createAsyncThunk<void>(BoardActionsTypes.COPY_TO_BUFFER, async (_, { dispatch, getState }) => {
  const state = getState() as TState,
    activeBoardElements = getActiveBoardElements(state);

  if (activeBoardElements && activeBoardElements.length > 0) {
    const buffer = await dispatch(getAllWidgetByIdsAction(activeBoardElements)).unwrap();

    if (buffer.length > 0) {
      dispatch(setBuffer(buffer));
    }
  }
});

export const getDisableDraggingActiveWidgets = createAsyncThunk<boolean, string[]>(
  BoardActionsTypes.GET_DISABLE_DRAGGING_ACTIVE_WIDGETS,
  async (widgetIds, { dispatch, getState }) => {
    const state = getState() as TState;
    const allWidgets = await dispatch(getAllWidgetByIdsAction(widgetIds)).unwrap();
    const activePage = getActivePageId(state);
    const allGroups = getWidgetsGroupsByPageId(activePage)(state);

    let hasWidgetDisableDragging = false;

    const hasDisableDraggingParentGroup = (idGroup: string): boolean => {
      const group = allGroups.find(({ id }) => id === idGroup);

      if (group?.type === GroupTypeEnum.GROUP) {
        return group.viewSettings.disableDragging || hasDisableDraggingParentGroup(group.parentGroupId || '');
      }
      return false;
    };

    for (const widget of allWidgets) {
      const groupIdVisualisation = (widget as DefaultVisualisationOptionsType)?.viewSettings?.group?.groupId;
      const groupIdFilter = (widget as FilterDataType)?.group?.groupId;
      const groupIdGroup = (widget as GroupWidgetSettingsInterface).parentGroupId;

      if (groupIdVisualisation && !isNull(groupIdVisualisation)) {
        hasWidgetDisableDragging = hasDisableDraggingParentGroup(groupIdVisualisation);
      }
      if (groupIdFilter && !isNull(groupIdFilter)) {
        hasWidgetDisableDragging = hasDisableDraggingParentGroup(groupIdFilter);
      }
      if (groupIdGroup && !isNull(groupIdGroup)) {
        hasWidgetDisableDragging = hasDisableDraggingParentGroup(groupIdGroup);
      }

      if ((widget as DefaultVisualisationOptionsType)?.viewSettings?.disableDragging) {
        hasWidgetDisableDragging = true;
        break;
      }
      if ((widget as FilterDataType)?.disableDragging) {
        hasWidgetDisableDragging = true;
        break;
      }
      if ((widget as GroupWidgetSettingsInterface)?.viewSettings?.disableDragging) {
        hasWidgetDisableDragging = true;
        break;
      }
    }
    return hasWidgetDisableDragging;
  },
);

export const getAllWidgetByIdsAction = createAsyncThunk<BufferType[], string[]>(
  BoardActionsTypes.GET_ALL_WIDGET_BY_IDS,
  async (widgetIds, { getState }) => {
    const state = getState() as TState;

    const buffer: BufferType[] = widgetIds
      .map((elementId) => {
        const visualisation = getVisualisationById(elementId)(state);
        const filter = getFilterById(elementId)(state);
        const group = getWidgetGroupById(elementId)(state);
        const zIndex = getLayerIndexById(elementId)(state);

        return { element: visualisation || filter || group, zIndex };
      })
      .filter((item): item is { element: BufferType; zIndex: number } => !isUndefined(item.element))
      .sort((a, b) => a.zIndex - b.zIndex)
      .map(({ element }) => element);

    return buffer;
  },
);

export const cutToBufferAction = createAsyncThunk(BoardActionsTypes.CUT_TO_BUFFER, (buffer, { dispatch }) => {
  dispatch(copyToBufferAction());

  dispatch(removeBoardElementAction());
});

export const addNewWidgetsFromBufferAction = createAsyncThunk<
  void,
  {
    id: string;
    bufferItem: BufferType;
    shiftActive?: boolean;
    newParentGroupId?: string;
    activeBoardElements?: string[] | null;
    activePageId?: string;
    groupPositionConfig?: BoardPositionConfigInterface;
  }
>(
  BoardActionsTypes.ADD_NEW_WIDGETS_FROM_BUFFER,
  async (
    { id, bufferItem, newParentGroupId, activeBoardElements, activePageId, groupPositionConfig, shiftActive },
    { dispatch, getState },
  ) => {
    const state = getState() as TState;
    const pageId = activePageId || getActivePageId(state);
    const activeBoardElement = activeBoardElements?.[0] || (isNull(activeBoardElements) && null);
    const pasteInGroup = activeBoardElement && getWidgetGroupById(activeBoardElement)(state);
    const visualisations = getWidgetsByPageId(pageId)(state);
    const filters = getFiltersByPageId(pageId)(getState() as TState);

    if ((bufferItem as GroupWidgetSettingsInterface).type === GroupTypeEnum.GROUP) {
      const groups = getWidgetsGroupsByPageId(bufferItem.pageId)(getState() as TState);

      const bufferGroup = bufferItem as GroupWidgetSettingsInterface;
      const { parentGroupId, isGrouped, name, type } = bufferGroup;

      const newNameGroups = generateWidgetName(groups, type, name, true);
      const newWidgetIds: string[] = [];
      const isGroupedValue = pasteInGroup ? true : isNull(activeBoardElement) ? false : isGrouped;

      if (bufferGroup.widgetIds) {
        const buffer = await dispatch(getAllWidgetByIdsAction(bufferGroup.widgetIds)).unwrap();

        buffer.forEach((element) => {
          const widgetId = v4();
          newWidgetIds.push(widgetId);

          dispatch(
            addNewWidgetsFromBufferAction({
              id: widgetId,
              bufferItem: element,
              newParentGroupId: id,
              activePageId: pageId,
            }),
          );
        });
      }

      const position = getPositionConfigForPasteWidget({
        isGroupedValue,
        newParentGroupId,
        positionConfig: bufferGroup.positionConfig,
        groupPositionConfig,
        shiftActive,
      });

      dispatch(
        addWidgetGroupByDataAction({
          ...bufferGroup,
          name: newNameGroups,
          pageId,
          id,
          widgetIds: newWidgetIds,
          isGrouped: isGroupedValue,
          parentGroupId: pasteInGroup ? pasteInGroup.id : isNull(activeBoardElement) ? null : newParentGroupId || parentGroupId,
          ...(position && { positionConfig: position }),
        }),
      );
    } else if ((bufferItem as FilterDataType)?.type) {
      const filterData = bufferItem as FilterDataType;
      const {
        group: { isGrouped, groupId, positionConfig },
        name,
        type,
      } = filterData;
      const isGroupedValue = pasteInGroup ? true : isNull(activeBoardElement) ? false : isGrouped;

      const newNameFilter = generateWidgetName(filters, type, name, true);
      const position = getPositionConfigForPasteWidget({
        isGroupedValue,
        newParentGroupId,
        positionConfig: filterData.positionConfig,
        groupPositionConfig,
        shiftActive,
      });

      dispatch(
        addFilterByDataAction({
          ...filterData,
          name: newNameFilter,
          pageId,
          id,
          group: {
            positionConfig,
            isGrouped: isGroupedValue,
            groupId: pasteInGroup ? pasteInGroup.id : isNull(activeBoardElement) ? null : newParentGroupId || groupId,
          },
          ...(position && { positionConfig: position }),
        }),
      );
    } else {
      const visualisationData = bufferItem as DefaultVisualisationOptionsType;
      const { viewSettings, visualisationType } = visualisationData;
      const {
        group: { isGrouped, groupId, positionConfig },
        name,
      } = viewSettings;
      const isGroupedValue = pasteInGroup ? true : isNull(activeBoardElement) ? false : isGrouped;

      const newNameVisualisation = generateWidgetName(visualisations, visualisationType, name, true);
      const position = getPositionConfigForPasteWidget({
        isGroupedValue,
        newParentGroupId,
        positionConfig: visualisationData.positionConfig,
        groupPositionConfig,
        shiftActive,
      });

      dispatch(
        addVisualisationByData({
          ...visualisationData,
          id,
          pageId,
          viewSettings: {
            ...viewSettings,
            name: newNameVisualisation,
            group: {
              positionConfig,
              isGrouped: isGroupedValue,
              groupId: pasteInGroup ? pasteInGroup.id : isNull(activeBoardElement) ? null : newParentGroupId || groupId,
            },
          },
          ...(position && { positionConfig: position }),
        }),
      );
    }

    if (pasteInGroup) {
      dispatch(
        updateWidgetGroupAction({
          id: pasteInGroup.id,
          settings: { widgetIds: [...(pasteInGroup.widgetIds || []), id] },
        }),
      );
    }

    dispatch(addToLayerAction(id));
  },
);

export const pasteFromBufferAction = createAsyncThunk<void, KeyboardEvent>(
  BoardActionsTypes.PASTE_FROM_BUFFER,
  (event, { dispatch, getState }) => {
    const state = getState() as TState;
    const buffer = getBuffer(state);
    const activeBoardElements = getActiveBoardElements(state);
    const groupPositionConfig = buffer && buffer.length > 1 && calculateGroupPositionConfig(buffer);
    const shiftActive = event.shiftKey;

    if (buffer) {
      buffer.forEach((bufferItem, index) => {
        const id = v4();
        dispatch(
          addNewWidgetsFromBufferAction({
            id,
            bufferItem,
            activeBoardElements,
            ...(groupPositionConfig && { groupPositionConfig }),
            shiftActive,
          }),
        );

        dispatch(setActiveBoardElementAction(index === 0 ? { id } : { id, type: BoardElementActivateEnum.MULTIPLE }));
      });
    }
  },
);

export const addToLayerAction = createAsyncThunk(BoardActionsTypes.ADD_TO_LAYER, (id: string, { dispatch, getState }) => {
  const state = getState() as TState,
    pageId = getActivePageId(state) || '';

  dispatch(addToLayer({ id, pageId }));
});

export const deleteFromLayerByIdAction = createAsyncThunk(
  BoardActionsTypes.DELETE_FROM_LAYER,
  (id: string, { dispatch, getState }) => {
    const state = getState() as TState,
      pageId = getActivePageId(state) || '';

    dispatch(deleteFromLayer({ id, pageId }));
  },
);

export const deleteLayerByPageIdAction = createAsyncThunk(
  BoardActionsTypes.DELETE_LAYER_BY_PAGE_ID,
  (pageId: string, { dispatch }) => {
    dispatch(deleteLayer(pageId));
  },
);

export const updatesLayersAction = createAsyncThunk<void, UpdateLayersPayload>(
  BoardActionsTypes.UPDATE_LAYERS,
  (payload, { dispatch }) => {
    dispatch(updateLayers(payload));
  },
);

export const addLayerByPageIdAction = createAsyncThunk(BoardActionsTypes.ADD_LAYER_BY_PAGE_ID, (pageId: string, { dispatch }) => {
  dispatch(updatesLayersAction({ pageId, layers: [] }));
});

export const updateLayersByIdsAction = createAsyncThunk<void, MoveLayersPayload>(
  BoardActionsTypes.UPDATE_BY_ID_LAYERS,
  ({ id, moveTo }, { dispatch, getState }) => {
    const state = getState() as TState,
      pageId = getActivePageId(state) || '',
      layers = getLayerByPageId(pageId)(state),
      indexOfLayers = layers.findIndex((layerId) => id === layerId),
      { newArray } = moveArrayItem(layers, indexOfLayers, moveTo);

    dispatch(updatesLayersAction({ pageId, layers: newArray }));
  },
);

export const loadLayersAction = createAsyncThunk(
  BoardActionsTypes.LOAD_LAYERS,
  async ({ pageId, projectId }: ProjectIdWithType<PageIdInterface>, { getState, dispatch, signal }) => {
    const layerAlreadyLoaded = getLayerAlreadyLoaded(pageId)(getState() as TState);

    if (!layerAlreadyLoaded) {
      const request = dispatch(loadLayersByPageIdAction({ pageId, projectId }));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadLayersByPageIdAction = createAsyncThunk<LayersMap, ProjectIdWithType<PageIdInterface>>(
  BoardActionsTypes.LOAD_LAYERS_BY_PAGE_ID,
  async ({ pageId, projectId }, { rejectWithValue, signal }) => {
    try {
      const response = await loadLayersByPageId({ pageId, projectId }, { signal });

      return { [pageId]: response.data.layers };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return {};
    }
  },
);

export const loadLayersFromSnapshotAction = createAsyncThunk<LayersMap, SettingsSnapshotType['layers']>(
  BoardActionsTypes.LOAD_LAYERS_FROM_SNAPSHOT,
  (layers) => layers.reduce<LayersMap>((result, { pageId, layers }) => ({ ...result, [pageId]: layers }), {}),
);

export const clearBoardStore = createAsyncThunk(BoardActionsTypes.CLEAR_BOARD_STORE, (_, { dispatch }) => {
  dispatch(setSlice(initialBoardStoreState));
});

export const moveVisualization = createAsyncThunk<
  unknown,
  {
    typeMove: MoveType;
  }
>(BoardActionsTypes.MOVE_VISUALIZATION, ({ typeMove }, { dispatch, getState }) => {
  const state = getState() as TState;
  const activeBoardElements = getActiveBoardElements(state);
  const activePage = getActivePage(state);

  if (activeBoardElements && activePage) {
    const activeBoardElement = activeBoardElements[0];

    const activeVisualisation = getVisualisationById(activeBoardElement)(state),
      activeFilter = getFilterById(activeBoardElement)(state),
      buffer = activeVisualisation || activeFilter,
      isDisabledMoving = activeVisualisation?.viewSettings.disableDragging || activeFilter?.disableDragging,
      { gridSpacing } = getProjectSettings(state),
      sizePage = activePage.boardSettings.sizes;

    if (buffer && !isDisabledMoving) {
      const newPositionConfig = updateMoveXY({ positionConfig: buffer.positionConfig, type: typeMove, gridSpacing, sizePage });

      if (activeVisualisation) {
        return dispatch(updatePositionConfigByIdAction({ id: activeBoardElement, positionConfig: newPositionConfig }));
      }

      return dispatch(updateFilterAction({ positionConfig: newPositionConfig }));
    }
  }
});

const updateMoveXY = ({ positionConfig, type, gridSpacing, sizePage }: UpdateMoveXYInterface): BoardPositionConfigInterface => {
  const isAxisX = type === 'left' || type === 'right';
  const add = type === 'down' || type === 'right';
  const { width, height } = sizePage;

  let newX = positionConfig.x;
  let newY = positionConfig.y;
  const widthWidget = positionConfig.width;
  const heightWidget = positionConfig.height;

  if (isAxisX) {
    newX = add ? newX + gridSpacing : newX - gridSpacing;
  } else {
    newY = add ? newY + gridSpacing : newY - gridSpacing;
  }

  newX = Math.round(newX / gridSpacing) * gridSpacing;
  newY = Math.round(newY / gridSpacing) * gridSpacing;

  if (newX < 0) {
    newX = 0;
  } else if (newX + widthWidget > width) {
    newX = width - widthWidget;
  }

  if (newY < 0) {
    newY = 0;
  } else if (newY + heightWidget > height) {
    newY = height - heightWidget;
  }

  return {
    ...positionConfig,
    x: newX,
    y: newY,
  };
};

export const addStyleBufferAction = createAsyncThunk<void>(
  BoardActionsTypes.ADD_STYLE_BUFFER,
  async (_, { dispatch, getState }) => {
    const state = getState() as TState;
    const activeElements = getActiveBoardElements(state);
    const id = getActiveBoardElementFunc({ activeElements });

    if (isNull(id)) return;

    const visualizationViewSettings = getActiveVisualisationSettings(state)?.viewSettings;
    const filter = getActiveFilter(state);
    const group = getWidgetGroupById(id)(state);

    const dataStyle = visualizationViewSettings || filter || group?.viewSettings;

    if (dataStyle) {
      if ('positionConfig' in dataStyle) {
        const { positionConfig, ...rest } = dataStyle;
        dispatch(addStyleBuffer(rest));
      }

      const { name, disableDragging: _disableDragging, group, widthOption, ...rest } = dataStyle;
      dispatch(addStyleBuffer(rest));
    }
  },
);

export const pasteStyleBufferAction = createAsyncThunk<void>(
  BoardActionsTypes.PASTE_STYLE_BUFFER,
  async (_, { dispatch, getState }) => {
    const state = getState() as TState;
    const activeBoardElements = getActiveBoardElements(state);
    const styleBuffer = getStyleBuffer(state);

    if (activeBoardElements) {
      activeBoardElements.map((elementId) => {
        const filter = getFilterById(elementId)(state);
        const group = getWidgetGroupById(elementId)(state);
        const groupViewSettings = group?.viewSettings;
        const groupId = group?.id;

        const visualisationViewSettings = getVisualisationById(elementId)(state)?.viewSettings;

        if (styleBuffer) {
          if (visualisationViewSettings) {
            dispatch(
              updateStyleViewSettingsByIdAction({
                id: elementId,
                viewSettings: deepMergeByReference(styleBuffer, visualisationViewSettings),
              }),
            );
          }
          if (filter) {
            dispatch(updateFilterAction({ ...deepMergeByReference(styleBuffer, filter) }));
          }

          if (groupViewSettings && groupId) {
            dispatch(
              updateViewSettingsWidgetGroupAction({
                id: groupId,
                viewSettings: deepMergeByReference(styleBuffer, groupViewSettings),
              }),
            );
          }
        }
      });
    }
  },
);
