import { Modal } from '@mui/material';
import { HeaderSettingsAreaLayout, HeaderSettingsAreaLayoutProps } from 'modules/settingsContainer/FieldSettingsArea';
import { MODAL_UNIVERSAL } from 'modules/workspace/constans';
import React, { FC } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { ReactChildrenType } from 'types/global';
import { BoxModal } from './styles';

interface ModalUniversalProps extends Pick<HeaderSettingsAreaLayoutProps, 'headerText' | 'onClose'> {
  open: boolean;
  disabledOutsideClose?: boolean;
  children?: ReactChildrenType;
  width?: string;
  maxHeight?: string;
  height?: string;
  padding?: string;
  position?: 'relative' | 'absolute' | 'static';
  disablePortal?: boolean;
  disableEscapeKeyDown?: boolean;
}

export const ModalUniversal: FC<ModalUniversalProps> = ({
  children,
  width,
  maxHeight,
  padding = '12px',
  position = 'relative',
  height,
  disabledOutsideClose = false,
  open,
  onClose,
  headerText,
  disableEscapeKeyDown,
  disablePortal = false,
}) => {
  const isClose = !disabledOutsideClose ? onClose : () => {};

  return (
    <>
      {/* TODO: MuiPaper-root is crutch, remove ZIndex */}
      {/* TODO: Delete MuiPaper-root and adding custom className for adding to isClickToItem array */}
      {/* TODO: disablePortal ломает открытие модалок SQL, удалить после релиза */}
      {/* TODO: onClick сделать опционально */}
      <Modal
        open={open}
        onClose={isClose}
        className={`MuiPaper-root ${MODAL_UNIVERSAL}`}
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableEnforceFocus
        disablePortal={disablePortal}
        onClick={(e) => {
          disablePortal && e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Delete') e.stopPropagation();
          if (e.key === 'Backspace') e.stopPropagation();
        }}
      >
        <BoxModal width={width} maxHeight={maxHeight} height={height}>
          <HeaderSettingsAreaLayout height="100%" maxHeight="100%" onClose={onClose} headerText={headerText}>
            <FlexContainer width="100%" flex="1 1 0" padding={padding} position={position}>
              {children}
            </FlexContainer>
          </HeaderSettingsAreaLayout>
        </BoxModal>
      </Modal>
    </>
  );
};
