import { FunctionIcon } from 'assets/icons/forDelete';
import { SetupAlterIcon } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ColorPicker, ColorPickerProps } from 'modules/settingsContainer/ColorPicker';
import {
  ColorPickerTypesType,
  ColorValuesByThemeType,
  PaletteValuesByThemeType,
} from 'modules/settingsContainer/ColorPicker/types';
import { PriorityChangerButtons, PriorityChangerButtonsProps } from 'modules/settingsContainer/PriorityChangerButtons';
import {
  SettingsFieldContainer,
  SetupButtonContainer,
  SetupIconWrapper,
} from 'modules/settingsContainer/SettingsFieldEntry/styles';
import { StyledTooltip } from 'modules/ui/StyledTooltip';
import TextField from 'modules/ui/TextField';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { SettingsFieldWrapper, SettingsFieldWrapperProps } from 'modules/ui/wrappers/SettingsFieldWrapper';
import React, { MouseEventHandler, useCallback, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ReactChildrenType } from 'types/global';
import { formattingNameIncision } from 'utils/formatting';
import { checkingName } from 'utils/utils';
import { v4 } from 'uuid';
import isUndefined from 'lodash/isUndefined';

interface SettingsFieldEntryProps<ColorValue extends PaletteValuesByThemeType | ColorValuesByThemeType>
  extends PriorityChangerButtonsProps,
    SettingsFieldWrapperProps {
  children?: ReactChildrenType;
  leftTextField?: ReactChildrenType;
  beforeField?: ReactChildrenType;
  fieldValue: string;
  onFieldChange: (fieldValue: string) => void;
  canChangeField?: boolean;
  disableChangePriory?: boolean;
  onSetupClick?: MouseEventHandler<HTMLButtonElement>;
  setupIsOpen?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disableColorPicker?: boolean;
  disabledViewColorPicker?: boolean;
  disabledViewSetup?: boolean;
  disabledViewChildren?: boolean;
  colorByHand?: boolean;
  disabledTextField?: boolean;
  colorPickerType?: ColorPickerTypesType;
  onChangeColors?: ColorPickerProps<ColorValue>['onChange'];
  colorsValue?: ColorPickerProps<ColorValue>['value'];
  isHiddenActivatorColor?: boolean;
  disabledChangeField?: boolean;
  disabledHeaderSection?: boolean;
  paddingSettingsContainer?: string;
  allNames?: string[];
}

export const SettingsFieldEntry = <ColorValue extends PaletteValuesByThemeType | ColorValuesByThemeType>({
  fieldValue,
  onFieldChange,
  canChangeField = true,
  beforeField,
  isHiddenActivatorColor,
  onSetupClick,
  setupIsOpen = false,
  onUpClick,
  onDownClick,
  isSelected,
  isActive,
  onClick,
  disableColorPicker,
  onChangeColors,
  colorsValue,
  colorPickerType,
  paddingSettingsContainer,
  disableChangePriory = false,
  disabledViewColorPicker = false,
  disabledViewSetup = false,
  disabledViewChildren = false,
  colorByHand = true,
  disabledTextField = false,
  disabledChangeField = false,
  disabledBorderContainer = false,
  disabledHeaderSection = false,
  children,
  leftTextField,
  allNames,
}: SettingsFieldEntryProps<ColorValue>) => {
  const [keyFieldName, setKeyFieldName] = useState<string>(v4());
  const onChangeBlur = useCallback(
    (value: string) => {
      if (!isUndefined(allNames)) {
        const isSameName = checkingName({
          allNames,
          oldName: fieldValue,
          currentName: formattingNameIncision(value),
          onChange: (checkName) => {
            onFieldChange(checkName);
          },
        });
        if (!isSameName) {
          setKeyFieldName(v4());
        }
      }

      if (isUndefined(allNames)) {
        onFieldChange(formattingNameIncision(value));
      }
    },
    [allNames, fieldValue, onFieldChange],
  );

  return (
    <SettingsFieldWrapper
      onClick={onClick}
      isSelected={isSelected}
      isActive={isActive}
      disabledViewSetup={disabledViewSetup}
      disabledBorderContainer={disabledBorderContainer}
      paddingSettingsContainer={paddingSettingsContainer}
    >
      {!disabledHeaderSection && (
        <SettingsFieldContainer>
          {!disabledViewColorPicker &&
            (colorByHand ? (
              <ColorPicker
                closeOnClick
                type={colorPickerType}
                onChange={onChangeColors}
                value={colorsValue}
                disabled={disableColorPicker}
                isHiddenActivatorColor={isHiddenActivatorColor}
              />
            ) : (
              <StyledTooltip title="Редактор кода">
                <IconWrapper
                  hoverColorVar={ColorVarsEnum.Level_1}
                  colorVar={ColorVarsEnum.Level_3}
                  containerWidth="20px"
                  containerHeight="20px"
                  iconHeight="16px"
                  iconWidth="16px"
                  Icon={FunctionIcon}
                />
              </StyledTooltip>
            ))}

          {beforeField && <FlexContainer flex="1 1 0">{beforeField}</FlexContainer>}
          {!disabledChangeField && (
            <>
              {canChangeField ? (
                <>
                  <TextField
                    key={keyFieldName}
                    name="xAxisName"
                    width="100%"
                    needBackground={false}
                    needBorderBottom={false}
                    value={fieldValue}
                    disabled={disabledTextField}
                    useDebounce
                    onChangeBlur={onChangeBlur}
                    formattingValue={(value) => formattingNameIncision(value)}
                  />
                  {leftTextField}
                </>
              ) : (
                <FlexContainer flex="1 1 0" height="30px" alignItems="center">
                  <PrimaryTextSpan lineHeight="14px" fontSize="14px" marginLeft="8px" color={`var(${ColorVarsEnum.Level_1})`}>
                    {fieldValue}
                  </PrimaryTextSpan>
                </FlexContainer>
              )}
            </>
          )}
          <SetupButtonContainer disabledViewSetup={disabledViewSetup} isSelected={isSelected}>
            <TooltipIconButton
              title="Настроить"
              disabled={!onSetupClick}
              needBackground={false}
              leftIcon={
                <SetupIconWrapper isActive={setupIsOpen}>
                  <SetupAlterIcon />
                </SetupIconWrapper>
              }
              onClick={onSetupClick}
            />
          </SetupButtonContainer>
        </SettingsFieldContainer>
      )}
      {isSelected && !disabledViewChildren && (
        <FlexContainer alignItems="center" gap="10px">
          <FlexContainer flex="1 1 0">{children}</FlexContainer>
          {!disableChangePriory && <PriorityChangerButtons onDownClick={onDownClick} onUpClick={onUpClick} />}
        </FlexContainer>
      )}
    </SettingsFieldWrapper>
  );
};
