import { AxiosResponse } from 'axios';
import { axiosClient } from 'services/ApiClient';
import { DeleteGroupSourceAccessByIdPayload, DeleteUserSourceAccessByIdPayload } from 'store/reducers/adminSources/types';
import {
  AddUsersGroupsSourceAccessPayload,
  UpdateGroupSourceAccessByIdPayload,
  UpdateUserSourceAccessByIdPayload,
} from 'types/types';

export const loadAdminSourceUsersAndGroups = (
  sourceId: string,
): Promise<AxiosResponse<FastBoard.API.ApiAdminSourceUsersAndGroupsListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/source/${sourceId}/groups-and-users`);

export const loadAdminSources = (): Promise<AxiosResponse<FastBoard.API.ApiAdminSourcesListResponseDTO>> =>
  axiosClient.get(`/api/v2/admin/sources`);

export const deleteAdminSource = (sourceId: string) =>
  axiosClient.delete<string, AxiosResponse<FastBoard.API.ApiAdminSourceDeleteResponseDTO>>(`/api/v2/admin/source/${sourceId}`);

export const deleteSourceAccessGroup = ({ sourceId, groupId }: DeleteGroupSourceAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/admin/source/${sourceId}/group/${groupId}/delete`);

export const deleteSourceAccessUser = ({ sourceId, userId }: DeleteUserSourceAccessByIdPayload) =>
  axiosClient.delete<string, AxiosResponse<string>, string>(`/api/v2/admin/source/${sourceId}/user/${userId}/delete`);

export const addUsersGroupsSourceAccess = ({ sourceId, groups, users, type }: AddUsersGroupsSourceAccessPayload) =>
  axiosClient.post<
    string,
    AxiosResponse<FastBoard.API.ApiAdminSourceAddUsersAndGroupsResponseDTO>,
    FastBoard.API.ApiAdminSourceAddGroupsAndUsersDTO
  >(`/api/v2/admin/source/${sourceId}/groups-and-users/add`, {
    groups: groups || [],
    users: users || [],
    type,
  });

export const updateSourceAccessGroup = ({ sourceId, type, groupId }: UpdateGroupSourceAccessByIdPayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminSourceGroupResponseDTO>,
    FastBoard.API.ApiAdminSourceUpdateTypeFromGroupDTO
  >(`/api/v2/admin/source/${sourceId}/group/${groupId}/type`, { type });

export const updateSourceAccessUser = ({ sourceId, type, userId }: UpdateUserSourceAccessByIdPayload) =>
  axiosClient.put<
    string,
    AxiosResponse<FastBoard.API.ApiAdminSourceUserResponseDTO>,
    FastBoard.API.ApiAdminSourceUpdateTypeFromUserDTO
  >(`/api/v2/admin/source/${sourceId}/user/${userId}/type`, { type });
