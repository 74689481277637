import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setSliceFn } from 'constants/store';
import { initialBlockVisibilitySettingsState } from './constants';
import { DefaultOpenListEnum } from 'types/global';

export const blockVisibilitySettingsSlice = createSlice({
  name: 'blockVisibilitySettings',
  initialState: initialBlockVisibilitySettingsState,
  reducers: {
    blockToggleSetting: (state, { payload: titleText }: PayloadAction<string>) => {
      if (state.blockVisibility[titleText]) {
        state.blockVisibility[titleText].isOpen = !state.blockVisibility[titleText].isOpen;
      } else {
        state.blockVisibility[titleText] = { isOpen: true };
      }
    },
    blockSoftwareUnitToggleSetting: (state, { payload: titleText }: PayloadAction<DefaultOpenListEnum>) => {
      if (state.blockingSoftwareUnits[titleText]) {
        state.blockingSoftwareUnits[titleText].isOpen = !state.blockingSoftwareUnits[titleText].isOpen;
      } else {
        state.blockingSoftwareUnits[titleText] = { isOpen: true };
      }
    },
    updateAllMainContainersOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.allMainContainersIsOpen = payload;
      const newValue = state.allMainContainersIsOpen;

      Object.keys(state.blockVisibility).forEach((key) => {
        state.blockVisibility[key].isOpen = newValue;
      });
    },
    setSlice: setSliceFn,
  },
});

export const { setSlice, blockToggleSetting, updateAllMainContainersOpen, blockSoftwareUnitToggleSetting } =
  blockVisibilitySettingsSlice.actions;

export default blockVisibilitySettingsSlice.reducer;
