import type { AppStateInterface } from 'store/reducers/appSlice/types';

export const initialAppStoreState: AppStateInterface = {
  appTabTitle: {
    title: '',
    description: '',
    fullName: 'Fastboard',
  },
  meta: null,
};
