import styled from 'styled-components';

export const DeleteButtonWrapper = styled.div`
  display: none;
`;

export type AddColorButtonWrapperProps = {
  pickerItemDirection?: 'flex-start' | 'center';
};

export const AddColorButtonWrapper = styled.div<AddColorButtonWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;

  :hover {
    span {
      display: none;
    }

    ${DeleteButtonWrapper} {
      display: flex;
    }
  }
`;
