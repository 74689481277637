import React, { useMemo } from 'react';
import { Tab } from '../types';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { SettingsTabs } from '../SettingsTabs';
import { updateAllMainContainersOpen } from 'store/reducers/blockVisibilitySettings';
import { useAppDispatch } from 'store';
import { useStyleBuffer } from 'utils/hooks/useStyleBuffer';

interface SettingsTabsWrapperProps {
  tabs: Tab[];
  disabledPointsMenu?: boolean;
}

export const SettingsTabsWrapper = ({ tabs, disabledPointsMenu = false }: SettingsTabsWrapperProps) => {
  const dispatch = useAppDispatch();

  const { onPasteStyleBuffer, onCopyStyleBuffer, disabledStyleCopy } = useStyleBuffer();

  const optionsPointsMenu: Option[] = useMemo(
    () => [
      {
        name: 'Раскрыть все секции',
        value: 'open',
        onClick: () => dispatch(updateAllMainContainersOpen(true)),
      },
      {
        name: 'Свернуть все секции',
        value: 'close',
        onClick: () => dispatch(updateAllMainContainersOpen(false)),
        isBorderBottom: true,
      },

      ...(!disabledStyleCopy
        ? [
            {
              name: 'Скопировать стиль',
              value: 'copy_style',
              onClick: onCopyStyleBuffer,
            },
          ]
        : []),

      {
        name: 'Применить стиль',
        value: 'close',
        onClick: onPasteStyleBuffer,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabledStyleCopy, onCopyStyleBuffer, onPasteStyleBuffer],
  );

  return <SettingsTabs tabs={tabs} optionsPointsMenu={!disabledPointsMenu ? optionsPointsMenu : undefined} />;
};
