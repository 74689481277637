import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { getActivePageId, getPageIds } from 'store/reducers/projectPages/getters';
import { getState } from 'store/utils';

export const getBoardStore = createSelector(getState, (state) => state.board);

export const getAlreadyLoadedContent = createSelector(getBoardStore, (state) => state.alreadyLoadedContent);

export const getBuffer = createSelector(getBoardStore, (state) => state.buffer);

export const getActiveBoardElements = createSelector(getBoardStore, (state) => state.activeBoardElements);
export const getActiveBoardElementInViewMode = createSelector(getBoardStore, (state) => state.activeBoardElementInViewMode);
export const getInfluenceFiltersMode = createSelector(getBoardStore, (state) => state.editInfluenceFiltersMode);

export const getIsMouseInside = createSelector(getBoardStore, (state) => state.isMouseInside);

export const getLayers = createSelector(getBoardStore, (state) => state.pagesLayers);
export const getServerStateOfPagesLayers = createSelector(getBoardStore, (state) => state.serverStateOfPagesLayers);
export const getHasChangesOfPagesLayers = createSelector([getServerStateOfPagesLayers, getLayers], (serverState, currentState) =>
  serverState === null ? false : !isEqual(serverState, currentState),
);

export const getLayerByPageId = (pageId: string) => createSelector(getLayers, (layers) => layers[pageId] || []);

export const getLayerAlreadyLoaded = (pageId: string) =>
  createSelector(getAlreadyLoadedContent, (alreadyLoadedContent) => alreadyLoadedContent.layers.has(pageId));

export const getArrayLayers = createSelector(
  getLayers,
  (pagesLayers) => Object.entries(pagesLayers).map(([pageId, layers]) => ({ pageId, layers: layers || [] })) || [],
);

export const getPageLayers = createSelector(
  [getActivePageId, getLayers],
  (activePageId, pagesLayers) => pagesLayers[activePageId],
);

export const getLayerIndexById = (id: string) =>
  createSelector(getPageLayers, (layer) => {
    if (layer) {
      return layer.findIndex((layerId) => layerId === id) + 1;
    }

    return -1;
  });

export const getLayersByPages = createSelector([getState, getPageIds], (state, pageIds) =>
  pageIds.reduce((results, pageId) => {
    if (getLayerAlreadyLoaded(pageId)(state)) {
      return [...results, { pageId, layers: getLayerByPageId(pageId)(state) }];
    }

    return results;
  }, [] as Array<{ pageId: string; layers: string[] }>),
);

export const getStyleBuffer = createSelector(getBoardStore, (state) => state.styleBuffer);
