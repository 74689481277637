import { ElementContainerSettings, GroupContainerSettings } from 'modules/settingsContainer';
import { Groups } from 'modules/settingsContainer/ColorPicker/styles';
import {
  colorGap,
  colorSize,
  maxColorInGroup,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/constants';
import { GroupIdInterface, PaletteItemInterface } from 'store/reducers/palettes/types';
import {
  GroupContainer,
  GroupNameTextSpan,
  GroupNameWrapper,
  GroupsWrapper,
  IconsGroupButton,
  NameAndDeleteContainer,
  StyledTextInput,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/styles';
import { AddIcon, CopyIcon, DeleteIcon } from 'assets/icons/withContainer';
import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActiveColorInterface,
  AddColorType,
  ChangeColorsType,
  ChangeColorType,
  ChangeGroupNameType,
  CopyGroupType,
  DeleteColorType,
  DeleteGroupType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import {
  defaultActiveColor,
  findCopyIndex,
  findLastCopyIndex,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/constants';
import { EditableItemInterface, FC, NoopValueType, OnValueChange } from 'types/global';
import { ClickAwayListener, Popover } from '@mui/material';
import { HlsColorEditorProps } from 'modules/ui/colors/HlsColorEditor';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from 'store';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';
import { PaletteColorBlock } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/PaletteColorBlock';
import { HexAndHLSColorInterface } from 'types/store';
import { EditPaletteColor } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/EditPaletteColor';
import { PriorityChangerButtons } from 'modules/settingsContainer/PriorityChangerButtons';
import { Button } from 'modules/ui';
import { MovePaletteGroupInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/types';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { copyAllColorsToGroupByCurrentPaletteAction, updatePaletteAction } from 'store/reducers/palettes/actions';
import { useKeyboardShortcuts } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/hooks/useKeyboardShortcuts';
import { useActiveColorData } from '../hooks/useActiveColorData';
import { usePopoverController } from '../hooks/usePopoverController';

interface EditableGroupNameProps extends OnValueChange<string>, GroupIdInterface {}

export const EditableGroupName = ({ groupId, value, onChange }: EditableGroupNameProps) => {
  const [editGroup, setEditGroup] = useState<EditableItemInterface<string> | null>(null);

  const onSave = () => {
    onChange(editGroup?.value || value);
    setEditGroup(null);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter' && !!editGroup) {
      onSave();
    }
  };

  return (
    <GroupNameWrapper>
      {editGroup?.key === groupId ? (
        <ClickAwayListener onClickAway={onSave}>
          <StyledTextInput
            autoFocus
            name={`edit_${groupId}`}
            width="100%"
            value={editGroup.value}
            size={editGroup.value.length + 5}
            onChange={(event) => setEditGroup({ value: event.target.value, key: groupId })}
            onKeyDown={onKeyDown}
          />
        </ClickAwayListener>
      ) : (
        <GroupNameTextSpan onDoubleClick={() => setEditGroup({ value, key: groupId })}>{value}</GroupNameTextSpan>
      )}
    </GroupNameWrapper>
  );
};

interface PaletteColorSettingsProps {
  palette: PaletteItemInterface;
  onAddColor: AddColorType;
  onDeleteColor: DeleteColorType;
  onDeleteGroup: DeleteGroupType;
  onColorChange: ChangeColorType;
  onCopyGroup: CopyGroupType;
  onColorsChange: ChangeColorsType;
  onGroupNameChange: ChangeGroupNameType;
  activeColor: ActiveColorInterface;
  onChangeActiveColor: NoopValueType<ActiveColorInterface>;
  synchronizationGroupMode: boolean;
  onMovePaletteGroup: NoopValueType<MovePaletteGroupInterface>;
}

export const PaletteColorSettings: FC<PaletteColorSettingsProps> = ({
  palette,
  onAddColor,
  onDeleteColor,
  onDeleteGroup,
  onCopyGroup,
  onColorChange,
  onColorsChange,
  onGroupNameChange,
  activeColor,
  onChangeActiveColor,
  synchronizationGroupMode,
  onMovePaletteGroup,
}) => {
  const dispatch = useAppDispatch();

  const groupRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const colorRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const buttonContainerRef = useRef<Record<string, HTMLDivElement | null>>({});

  const [copiedColor, setCopiedColor] = useState<HexAndHLSColorInterface | null>(null);

  const { isPopoverOpen, anchorPosition, activePopoverId, closePopover, openPopover } = usePopoverController(onChangeActiveColor);

  const isSelectedColor = useMemo(() => !!activeColor.activeColorIds, [activeColor.activeColorIds]);
  const activeColorData = useActiveColorData(palette, activeColor);
  const isLastGroup = useMemo(() => palette.groups.length === 1, [palette.groups.length]);
  const isLastColor = useMemo(() => isLastGroup && palette.groups[0].colors.length === 1, [isLastGroup, palette.groups]);
  const activeGroupId = useMemo(() => activeColor.activeColorIds?.groupId || '', [activeColor.activeColorIds?.groupId]);

  const handleColorSelectorClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, colorId: string, groupId: string, colorIndex: number) => {
      const groupElement = groupRefs.current[groupId];
      if (!groupElement) return;

      const { top: rectTop, left: rectLeft } = groupElement.getBoundingClientRect();
      const left = Math.max(rectLeft - 376, 0);
      const top = rectTop + window.scrollY;

      const isSamePopover = activePopoverId === colorId && isPopoverOpen;
      if (isSamePopover) {
        closePopover();
        return;
      }

      openPopover(colorId, { top, left }, { activeColorIds: { groupId, colorId }, colorIndex });
    },
    [activePopoverId, isPopoverOpen, closePopover, openPopover],
  );

  const onLocalDeleteColor = useCallback(() => {
    if (isSelectedColor && activeColor.activeColorIds && activeColor.colorIndex !== null) {
      const { colorId, groupId } = activeColor.activeColorIds;
      onDeleteColor({ colorId: colorId || '', groupId: activeGroupId });

      const colors = palette.groups.find((group) => group.id === groupId)?.colors;
      const newColors = [...(colors || [])];

      newColors.splice(activeColor.colorIndex, 1);

      const colorIndex = activeColor.colorIndex === 0 ? activeColor.colorIndex + 1 : activeColor.colorIndex - 1;
      const newActiveColorId = colors?.[colorIndex]?.id;
      const newActiveColorIndex = newColors.findIndex(({ id }) => id === newActiveColorId);

      if (newActiveColorId && newActiveColorIndex !== -1) {
        onChangeActiveColor({
          activeColorIds: { groupId, colorId: newActiveColorId },
          colorIndex: newActiveColorIndex,
        });
      }
    }
  }, [
    activeColor.activeColorIds,
    activeColor.colorIndex,
    activeGroupId,
    isSelectedColor,
    onChangeActiveColor,
    onDeleteColor,
    palette.groups,
  ]);

  const onLocalDeleteGroup = useCallback(
    (groupId: string) => {
      onDeleteGroup({ groupId });
      onChangeActiveColor(defaultActiveColor);
    },
    [onChangeActiveColor, onDeleteGroup],
  );

  const onLocalColorChange = useCallback<HlsColorEditorProps['onChange']>(
    (color) => {
      if (isSelectedColor && activeColor.activeColorIds && activeColorData) {
        const { groupId, colorId } = activeColor.activeColorIds;

        onColorChange({ groupId, colorId, color });
      }
    },
    [isSelectedColor, activeColor, activeColorData, onColorChange],
  );

  const onLocalColorsChange = useCallback<HlsColorEditorProps['onChange']>(
    (color, type) => {
      if (isSelectedColor && activeColor.activeColorIds && activeColorData) {
        const { groupId } = activeColor.activeColorIds;

        onColorsChange({ groupId, color: { [type]: color[type] } });
      }
    },
    [isSelectedColor, activeColor, activeColorData, onColorsChange],
  );

  const onHexAndHlsColor = useMemo(
    () =>
      debounce((color: HexAndHLSColorInterface, type: keyof HexAndHLSColorInterface) => {
        synchronizationGroupMode ? onLocalColorsChange(color, type) : onLocalColorChange(color, type);
      }, 300),
    [synchronizationGroupMode, onLocalColorsChange, onLocalColorChange],
  );

  useEffect(() => {
    onChangeActiveColor(defaultActiveColor);
  }, [onChangeActiveColor, palette.id]);

  const handleCopyGroup = useCallback(
    (activeGroupId: string) => {
      const activeGroup = palette.groups.find((group) => group.id === activeGroupId);

      if (activeGroup) {
        const nameParts = activeGroup.name.split(' ');
        const defaultName = nameParts[nameParts.length - 1].includes('(')
          ? nameParts.slice(0, nameParts.length - 1).join(' ')
          : activeGroup.name;
        const nextIndex = findCopyIndex(
          palette.groups.map(({ name }) => name),
          defaultName,
        );
        const name = `${defaultName} (${nextIndex})`;
        const colors = activeGroup.colors.map((color) => ({ ...color, id: uuidv4() }));
        const lastCopyIndex = findLastCopyIndex(palette.groups, defaultName);

        onCopyGroup({ group: { colors, name }, index: lastCopyIndex + 1 });
      }
    },
    [palette.groups, onCopyGroup],
  );

  const handleCopyColor = useCallback(
    async ({ isAddNewColorGroup, groupId }: { isAddNewColorGroup?: boolean; groupId?: string }) => {
      if (!isAddNewColorGroup && !activeColorData) return;

      const result = await onAddColor({
        color: isAddNewColorGroup ? undefined : activeColorData,
        groupId: isAddNewColorGroup ? (groupId as string) : activeGroupId,
      });

      if (result) {
        const { groupId, colorId } = result;
        const colorGroup = palette.groups.find((group) => group.id === groupId);

        if (colorGroup) {
          const colorIndex = colorGroup.colors.length - 1;
          onChangeActiveColor({ activeColorIds: { groupId, colorId }, colorIndex });
        }
      }
    },
    [activeColorData, activeGroupId, onAddColor, onChangeActiveColor, palette.groups],
  );

  const onClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const target = event.target as Node;
      const path = (event as MouseEvent & { composedPath?: () => EventTarget[] }).composedPath
        ? (event as MouseEvent & { composedPath: () => EventTarget[] }).composedPath()
        : [];

      const clickedOnColor = Object.values(colorRefs.current).some((ref) => ref && path.includes(ref));
      const clickedOnButton = Object.values(buttonContainerRef.current).some((ref) => ref && ref.contains(target));

      if (clickedOnColor || clickedOnButton) return;
      closePopover();
    },
    [closePopover],
  );

  const isAllActiveColor = useCallback(
    (groupId: string) => (activeColor.activeColorIds?.groupId === groupId ? synchronizationGroupMode : false),
    [activeColor.activeColorIds?.groupId, synchronizationGroupMode],
  );

  const onLocalCopyAllColors = useCallback(async () => {
    await dispatch(copyAllColorsToGroupByCurrentPaletteAction({ groupId: activeGroupId }));
  }, [dispatch, activeGroupId]);

  const moveActiveColor = useCallback(
    (moveTo: MoveToType) => {
      const sourceGroupColors = palette.groups.find((g) => g.id === activeGroupId)?.colors;
      const indexOfColor = sourceGroupColors?.findIndex(({ id }) => id === activeColor.activeColorIds?.colorId);

      if (sourceGroupColors && indexOfColor !== undefined && indexOfColor !== -1) {
        const { newArray } = moveArrayItem(sourceGroupColors, indexOfColor, moveTo);
        dispatch(
          updatePaletteAction({
            ...palette,
            groups: palette.groups.map((group) => (group.id === activeGroupId ? { ...group, colors: newArray } : group)),
          }),
        );
      }
    },
    [activeColor.activeColorIds, activeGroupId, dispatch, palette],
  );

  useKeyboardShortcuts({
    isSelectedColor,
    activeColorData,
    activeGroupId,
    onAddColor,
    onLocalDeleteColor,
    onChangeActiveColor,
    copiedColor,
    palette,
    moveActiveColor,
    setCopiedColor,
  });

  const disableDeleteButton = synchronizationGroupMode ? isLastGroup : !isSelectedColor || isLastColor;

  return (
    <>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <GroupsWrapper>
            {palette.groups.map(({ name, id: groupId, colors }) => (
              <GroupContainer key={groupId} width="100%" flexDirection="column" ref={(el) => (groupRefs.current[groupId] = el)}>
                <NameAndDeleteContainer>
                  {palette.showGroupNames && (
                    <EditableGroupName
                      groupId={groupId}
                      value={name}
                      onChange={(newName) => onGroupNameChange({ name: newName, groupId })}
                    />
                  )}
                  <IconsGroupButton>
                    <TooltipIconButton
                      title="Создать копию группы"
                      leftIcon={<CopyIcon />}
                      onClick={() => handleCopyGroup(groupId)}
                    />
                    <TooltipIconButton
                      title="Удалить группу"
                      leftIcon={<DeleteIcon />}
                      disabled={isLastColor}
                      onClick={() => onLocalDeleteGroup(groupId)}
                    />
                    <PriorityChangerButtons
                      onDownClick={() => onMovePaletteGroup({ groupId, moveTo: 'down' })}
                      onUpClick={() => onMovePaletteGroup({ groupId, moveTo: 'up' })}
                    />
                  </IconsGroupButton>
                </NameAndDeleteContainer>
                <Groups alignItems="center" maxColorInGroup={maxColorInGroup} colorGap={colorGap} colorSize={colorSize}>
                  {colors.map(({ id: colorId, hex, name }, colorIndex) => (
                    <PaletteColorBlock
                      ref={(el) => (colorRefs.current[colorId] = el)}
                      key={colorId}
                      name={name}
                      onColorSelectorClick={(event) => handleColorSelectorClick(event, colorId, groupId, colorIndex)}
                      colorId={colorId}
                      groupId={groupId}
                      colorIndex={colorIndex}
                      hex={hex}
                      onChange={onChangeActiveColor}
                      value={activeColor}
                      isAllActiveColor={isAllActiveColor(groupId)}
                    />
                  ))}
                  <Button
                    heightSize="small"
                    leftIcon={<AddIcon />}
                    onClick={() => handleCopyColor({ isAddNewColorGroup: true, groupId })}
                  />
                </Groups>
              </GroupContainer>
            ))}
          </GroupsWrapper>
        </ElementContainerSettings>
      </GroupContainerSettings>

      {activePopoverId && (
        <ClickAwayListener onClickAway={onClickAway}>
          <Popover
            ref={popoverRef}
            key={activePopoverId}
            open={isPopoverOpen}
            anchorReference="anchorPosition"
            anchorPosition={anchorPosition}
            onClose={closePopover}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {activeColorData && (
              <EditPaletteColor
                activeColorData={activeColorData}
                onHexAndHlsColor={onHexAndHlsColor}
                onClose={closePopover}
                onChangeCopyColor={
                  synchronizationGroupMode ? onLocalCopyAllColors : () => handleCopyColor({ isAddNewColorGroup: false })
                }
                onDeleteGroupColor={() => {
                  synchronizationGroupMode ? onLocalDeleteGroup(activeGroupId) : onLocalDeleteColor();
                  closePopover();
                }}
                disabledDeleteGroupColor={disableDeleteButton}
              />
            )}
          </Popover>
        </ClickAwayListener>
      )}
    </>
  );
};
