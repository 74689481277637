import { GroupContainerStylesEnum } from 'modules/groups/settings/ViewTab/types';
import { BoardPositionConfigInterface, IdInterface } from 'types/store';
import {
  DefaultViewSettingsInterface,
  IsVisibleInterface,
  VariablesSettingsInterface,
} from 'store/reducers/visualisations/types';
import { GroupTypeEnum } from 'store/reducers/filters/types';
import { MapRecordType } from 'types/global';
import { BufferType } from 'store/reducers/board/types';

export interface initialGroupsStateInterface {
  widgetGroups: MapRecordType<GroupWidgetSettingsInterface>;
  serverStateOfGroupsVisualisations: MapRecordType<GroupWidgetSettingsInterface> | null;
  groupsByPages: MapRecordType<Set<string>>;
  alreadyLoadedContent: {
    groups: Set<string>;
  };
}

export enum BoardElementActivateEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum ContainerValuesEnum {
  FIXED = 'fixed',
  ADAPTIVE = 'adaptive',
  STREAMLINED = 'streamlined',
}

export enum FlexDirectionEnum {
  ROW = 'row',
  COLUMN = 'column',
}

export interface GroupSettingsStylesInterface {
  direction: FlexDirectionEnum;
  wrap: boolean;
  align: GroupContainerStylesEnum | null;
  justify: GroupContainerStylesEnum | null;
}

export type VisualisationsGroupType = 'group';

export enum VisualisationsGroupTypesEnum {
  GROUP = 'group',
}

export type GroupViewSettingsInterface = GroupSettingsStylesInterface & DefaultViewSettingsInterface;

export interface WidgetGroupInterface {
  id: string;
  pageId: string;
  name?: string;
  positionConfig: BoardPositionConfigInterface;
  widgetIds: string[] | null;
  parentGroupId: string | null;
  isGrouped: boolean;
}

export interface GroupWidgetSettingsInterface extends WidgetGroupInterface, IsVisibleInterface {
  viewSettings: GroupViewSettingsInterface;
  type: GroupTypeEnum.GROUP;
  parentGroupId: string | null;
  events: GroupWidgetEventsInterface;
}

export interface GroupWidgetEventsInterface {
  variablesSettings: VariablesSettingsInterface;
}

export enum GroupsVisualisationsActionsTypes {
  ADD_WIDGET_GROUP = 'ADD_WIDGET_GROUP',
  HANDLE_ADD_WIDGET_GROUP = 'HANDLE_ADD_WIDGET_GROUP',
  MOVE_ELEMENT_INSIDE_GROUP = 'MOVE_ELEMENT_INSIDE_GROUP',
  ADD_WIDGET_GROUP_BY_DATA = 'ADD_WIDGET_GROUP_BY_DATA',
  REMOVE_WIDGET_GROUP = 'REMOVE_WIDGET_GROUP',
  UPDATE_WIDGET_GROUP = 'UPDATE_WIDGET_GROUP',
  RETURN_UPDATE_WIDGET_GROUP_POSITION = 'RETURN_UPDATE_WIDGET_GROUP_POSITION',
  UPDATE_WIDGET_GROUPS = 'UPDATE_WIDGET_GROUPS',
  UPDATE_OF_IDS = 'UPDATE_OF_IDS',
  UPDATE_VIEW_SETTINGS_WIDGET_GROUP = 'UPDATE_VIEW_SETTINGS_WIDGET_GROUP',
  UPDATE_WIDGET_GROUP_POSITION = 'UPDATE_WIDGET_GROUP_POSITION',
  LOAD_GROUPS_VISUALISATIONS = 'LOAD_GROUPS_VISUALISATIONS',
  LOAD_GROUPS_VISUALISATIONS_BY_PAGE_ID = 'LOAD_GROUPS_VISUALISATIONS_BY_PAGE_ID',
  DELETE_FULL_WIDGET_GROUP_BY_ID = 'DELETE_FULL_WIDGET_GROUP_BY_ID',
  UPDATE_WIDGETS_GROUP_SETTINGS = 'UPDATE_WIDGETS_GROUP_SETTINGS',
  HANDLE_REMOVE_FULL_WIDGET_GROUP = 'HANDLE_REMOVE_FULL_WIDGET_GROUP',
  REMOVE_ALL_SELECTED_GROUPS = 'REMOVE_ALL_SELECTED_GROUPS',
  UPDATE_WIDGETS_REMOVE_GROUP = 'UPDATE_WIDGETS_REMOVE_GROUP',
  ADJUST_CHILD_GROUP_POSITIONS = 'ADJUST_CHILD_GROUP_POSITIONS',
  CREATE_NEW_GROUP = 'CREATE_NEW_GROUP',
  REMOVE_GROUP_BY_WIDGET_IDS = 'REMOVE_GROUP_BY_WIDGET_IDS',
  REPEAT_ADD_WIDGET_GROUP = 'REPEAT_ADD_WIDGET_GROUP',
  REPEAT_REMOVE_WIDGET_GROUP = 'REPEAT_REMOVE_WIDGET_GROUP',
  REPEAT_UPDATE_WIDGET_GROUP = 'REPEAT_UPDATE_WIDGET_GROUP',
  DETERMINE_PARENT_GROUP_ID = 'DETERMINE_PARENT_GROUP_ID',
  LOAD_GROUPS_VISUALISATIONS_FROM_SNAPSHOT = 'LOAD_GROUPS_VISUALISATIONS_FROM_SNAPSHOT',
  REMOVE_GROUP = 'REMOVE_GROUP',
}

export type LoadGroupsVisualisationsOutput = Pick<initialGroupsStateInterface, 'groupsByPages' | 'widgetGroups'>;

export interface RemoveGroupVisualisationsInterface extends IdInterface {
  widgetIds: string[] | null;
  parentGroupId?: string | null;
  pageId?: string;
  addWidget?: boolean;
}

export interface UpdateGroupVisualisationsInterface extends IdInterface {
  settings: Partial<GroupWidgetSettingsInterface>;
}

export interface UpdateViewSettingsGroupVisualisationsInterface extends IdInterface {
  viewSettings: Partial<GroupViewSettingsInterface>;
}

export interface UpdateWidgetGroupsInterface {
  widgetIds: string[] | null;
  isGrouped: boolean;
  parentGroupId: string | null;
}

export interface UpdateGroupWidgetIdsInterface {
  widgetIds: string[] | null;
  groupId: string;
}

export interface CreateNewGroupActionInterface {
  groupId: string;
  activePageId: string;
  activeWidgets: string[] | null;
  groupPositionConfig: BoardPositionConfigInterface;
  parentGroupId: string | null;
  selectedElements: BufferType[];
}

export interface adjustChildGroupPositionsActionInterface {
  widgetGroups: WidgetGroupInterface[];
  selectedElements: BufferType[];
  groupPositionConfig: BoardPositionConfigInterface;
  parentGroupId: string | null;
}

export interface determineParentGroupIdActionInterface {
  widgetGroups: GroupWidgetSettingsInterface[];
  selectedElements: BufferType[];
  groupId: string;
}

export interface updateSettingsWidgetsInParentGroupProps extends IdInterface {
  activeGroup: WidgetGroupInterface;
  selectedElements: BufferType[] | null;
  widgetGroups: GroupWidgetSettingsInterface[];
  widgetIds: string[] | null;
  pageId?: string;
  isHistoryAction?: boolean;
}

export interface updateChildGroupsActionProps {
  widgetIds: string[];
  positionConfig: BoardPositionConfigInterface;
  parentGroupId: string | null;
  widgetGroups: GroupWidgetSettingsInterface[];
}

export interface updateWidgetsGroupActionProps {
  pageId: string;
  id: string;
  widgetIds: string[] | null;
  selectedElements: BufferType[] | null;
  parentGroupId: string | null;
  positionConfig: BoardPositionConfigInterface;
  isHistoryAction?: boolean;
}
