import { PositionSettingType, RoleType } from 'types/store';
import { CronHour } from 'types/types';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const startMobileSize = 1024;

export const roleItems: Array<{ name: string; value: RoleType }> = [
  { name: 'Админ', value: 'admin' },
  { name: 'Аналитик', value: 'analyst' },
  { name: 'Разработчик', value: 'developer' },
  { name: 'Зритель/Бизнес-пользователь', value: 'viewer' },
];

export const roleData: Record<RoleType, string> = {
  admin: 'Админ',
  analyst: 'Аналитик',
  developer: 'Разработчик',
  viewer: 'Зритель/Бизнес-пользователь',
};

export const defaultCronDate: CronHour = {
  type: 'hour',
  minutes: 0,
  hours: 0,
};

export const textAlignMapper: Record<PositionSettingType, 'left' | 'center' | 'right'> = {
  'flex-start': 'left',
  center: 'center',
  'flex-end': 'right',
};

export const elementColorsTooltip: Record<ColorVarsEnum, string> = {
  [ColorVarsEnum.Level_1]:
    'Заголовок виджета, название секции, неактивный фильтр, неактивный разрез, неактивная кнопка, текст в полях ввода и SQL-запросах, значения в виджетах, текст кнопок меню',
  [ColorVarsEnum.Level_2]: 'Названия страниц, подзаголовок виджета, метки осей, текст в легенде',
  [ColorVarsEnum.Level_3]: 'Подписи настроек меню',
  [ColorVarsEnum.Level_4]: 'Координатные оси графиков',
  [ColorVarsEnum.Level_5]: 'Разделители и рамки',
  [ColorVarsEnum.Accent]: 'Цвет активных элементов, режимов, страниц',
  [ColorVarsEnum.Alert]: 'Цвет активного элемента',
  [ColorVarsEnum.Level_5_page]: 'Фон страницы',
  [ColorVarsEnum.Level_6_application]: 'Фон приложения',
  [ColorVarsEnum.Level_4_widget]: 'Фон виджета',
  [ColorVarsEnum.Level_3_menu]: 'Фон меню',
  [ColorVarsEnum.Level_1_hover_btn]: 'Фон наведения на кнопку',
  [ColorVarsEnum.Level_2_btn]: 'Фон кнопки',
  [ColorVarsEnum.Sub_Level_1]: 'Подписи в подсказках',
  [ColorVarsEnum.Sub_Level_2]: 'Не используется',
  [ColorVarsEnum.Sub_Level_3]: 'Не используется',
  [ColorVarsEnum.Sub_Level_bg_1]: 'Фон в подсказках',
  [ColorVarsEnum.Sub_Level_bg_2]: 'Не используется',
  [ColorVarsEnum.Sub_Level_bg_3]: 'Не используется',
};

export const defaultFont = ', sans-serif';
