import { StyledIconWrapper, StyledIconWrapperProps } from './styles';
import { IconType } from 'assets/icons/withContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { lighten } from 'polished';
import { useSelector } from 'react-redux';
import { MouseEventHandler } from 'react';
import { getActiveThemeColors } from 'store/reducers/themes/getters';

interface IconWrapperProps extends Omit<StyledIconWrapperProps, 'hoverColorStroke' | 'colorStroke'> {
  Icon: IconType;
  rotate?: string;
  opacity?: number;
  fullColor?: boolean;
  colorVar?: ColorVarsEnum;
  hoverColorVar?: ColorVarsEnum;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  id?: string;
  margin?: string;
}

export const IconWrapper = ({
  Icon,
  opacity = 0.2,
  colorVar = ColorVarsEnum.Level_2,
  hoverColorVar = ColorVarsEnum.Level_2,
  id,
  ...styledProps
}: IconWrapperProps) => {
  const activeThemeColor = useSelector(getActiveThemeColors);

  return (
    <StyledIconWrapper
      id={id}
      colorStroke={activeThemeColor[colorVar]}
      hoverColorStroke={lighten(opacity, activeThemeColor[hoverColorVar])}
      {...styledProps}
    >
      <Icon id={id} />
    </StyledIconWrapper>
  );
};
