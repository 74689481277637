import { FC } from 'react';
import { Close8px } from 'assets/icons/forDelete';
import { SnackbarCloseIconWrapper } from 'constants/styles';
import { SnackbarMessage, useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

let snackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

const CustomSnackbarAction: FC<{ id: string | number }> = ({ id }) => (
  <SnackbarCloseIconWrapper onClick={() => snackbarRef.closeSnackbar(id)}>
    <Close8px />
  </SnackbarCloseIconWrapper>
);

export default {
  show(msg: SnackbarMessage, variant: VariantType = 'default'): void {
    snackbarRef.enqueueSnackbar(msg, {
      variant,
      action: (key) => (variant === 'error' || variant === 'success' ? <CustomSnackbarAction id={key} /> : null),
    });
  },
};
