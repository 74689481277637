import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { Select } from 'modules/ui';
import { AddIcon, CopyIcon, DeleteIcon, EditIcon } from 'assets/icons/withContainer';
import React from 'react';
import { PaletteItemInterface } from 'store/reducers/palettes/types';
import { NoopType, NoopValueType } from 'types/global';
import { TooltipIconButton } from 'modules/ui/TooltipIconButton';

interface PalettesHeaderProps {
  palettes: PaletteItemInterface[];
  activePalette: PaletteItemInterface;
  onAddPaletteClick: NoopType;
  onRenamePaletteClick: NoopType;
  onCopyPaletteClick: NoopType;
  onDeletePaletteClick: NoopType;
  onChangePalette: NoopValueType<string>;
}

export const PalettesHeader: React.FC<PalettesHeaderProps> = ({
  palettes,
  activePalette,
  onAddPaletteClick,
  onRenamePaletteClick,
  onCopyPaletteClick,
  onDeletePaletteClick,
  onChangePalette,
}) => {
  return (
    <MainContainerSettings>
      <GroupContainerSettings>
        <ElementContainerSettings>
          <GroupContainerSettings width="100%">
            <ElementContainerSettings width="100%" alignItems="center" justifyContent="space-between">
              <TooltipIconButton title="Создать палитру" leftIcon={<AddIcon />} onClick={onAddPaletteClick} />
              <Select
                needBackground={false}
                placeholder="Выбрать палитру"
                name="palette_name"
                options={palettes.map(({ id, name }) => ({ value: id, name }))}
                width="60%"
                value={activePalette.id}
                onChange={onChangePalette}
              />

              <TooltipIconButton
                title="Переименовать палитру"
                leftIcon={<EditIcon />}
                needBackground={false}
                onClick={onRenamePaletteClick}
              />

              <TooltipIconButton title="Создать палитру на основе текущей" leftIcon={<CopyIcon />} onClick={onCopyPaletteClick} />

              <TooltipIconButton
                title="Удалить палитру"
                leftIcon={<DeleteIcon />}
                onClick={onDeletePaletteClick}
                disabled={palettes.length === 1}
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
        </ElementContainerSettings>
      </GroupContainerSettings>
    </MainContainerSettings>
  );
};
