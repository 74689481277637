import { defaultDateFormat, defaultSqlDateFormat, getFromToFnByType } from 'constants/dates';
import { PeriodEnum } from 'modules/ui/inputs/DatePicker/types';
import { ColumnRef } from 'node-sql-parser';
import { Parser, Select } from 'node-sql-parser/build/db2';
import { EnabledFiltersDependencyInterface } from 'store/reducers/filters/types';
import { AST } from 'types/ast';
import { AstOperatorEnum } from 'types/types';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';
import { VisualisationOperationTypesEnum } from 'store/reducers/visualisations/types';
import isString from 'lodash/isString';

export const sqlParser = new Parser();

export const defaultSelectAST: Select = {
  type: 'select',
  columns: [],
  with: null,
  options: null,
  distinct: null,
  from: null,
  where: null,
  groupby: null,
  having: null,
  orderby: null,
  limit: null,
};

/* Generate Object AST from raw data */

export const getTableAndColumnByFieldName = (fieldName: string) => {
  const dividedFieldName = fieldName.split('.');

  let table = null,
    column = fieldName;

  if (dividedFieldName.length > 1) {
    [table, column] = dividedFieldName;
  }

  return { table, column };
};

export const getTableAndColumnByIncisionRequest = (incisionRequest: string) => {
  let table = null;
  let column = incisionRequest;

  const isComplex = incisionRequest.includes('(') && incisionRequest.includes(')');

  if (isComplex) {
    return { table, column };
  } else {
    const dividedFieldName = incisionRequest.split('.');

    if (dividedFieldName.length > 1) {
      [table, column] = dividedFieldName;
    }
  }

  return { table, column };
};

export const generateBasicColumn: (params: {
  alias: string | null;
  fieldName: string;
  incisionRequest?: string | null;
}) => AST.BasicColumn = ({ fieldName, alias, incisionRequest }) => {
  const getTableAndColumnFunc = incisionRequest ? getTableAndColumnByIncisionRequest : getTableAndColumnByFieldName;
  const filterRequest = incisionRequest ? fieldName || incisionRequest : fieldName;

  const { table, column } = getTableAndColumnFunc(filterRequest);

  return {
    as: alias,
    expr: { type: 'column_ref', column, table },
  };
};

export const generateBasicFunctionColumn: (params: {
  alias: string;
  fieldName: string;
  functionName: string;
}) => AST.BasicFunctionColumn = ({ fieldName, alias, functionName }) => {
  const { expr: argumentExpr } = generateBasicColumn({ fieldName, alias });
  const isCountUnique = functionName === VisualisationOperationTypesEnum.COUNTUNIQUE;
  const aggrFunctionName = isCountUnique ? VisualisationOperationTypesEnum.COUNT : functionName;
  const distinctValue = isCountUnique ? 'DISTINCT' : null;

  return {
    as: alias,
    expr: {
      type: 'aggr_func',
      name: aggrFunctionName,
      over: null,
      args: {
        distinct: distinctValue,
        orderby: null,
        parentheses: false,
        expr: argumentExpr,
      },
    },
  };
};

export const generateGroupByColumn: (params: { fieldName: string }) => AST.GroupByColumn = ({ fieldName }) => {
  const { expr: groupBy } = generateBasicColumn({ fieldName, alias: '' });

  return groupBy;
};

export const generateValueString: (value: string) => AST.ValueString = (value) => ({ type: 'string', value });

export const generateExpressionList: (
  values: string[] | AST.ValueString[] | Array<string | AST.ValueString>,
) => AST.ExpressionList = (values) => ({
  type: 'expr_list',
  value: values.map((value) => {
    if (typeof value === 'string') {
      return generateValueString(value);
    }
    return value;
  }),
});

export const generateIn: AST.GenerateFilterAstFnInterface = ({
  fieldName,
  values,
  operator = AstOperatorEnum.IN,
  incisionRequest,
}) => {
  const { expr: left } = generateBasicColumn({ fieldName, alias: '', incisionRequest }),
    right = generateExpressionList(values);

  return {
    type: 'binary_expr',
    left,
    operator,
    right,
  };
};

export const generateLike: AST.GenerateLikeAstFnInterface = ({ fieldName, filterString }) => {
  const { expr: left } = generateBasicColumn({ fieldName, alias: '' }),
    { expr: right } = generateBasicColumn({ fieldName: filterString, alias: '' });

  return {
    type: 'binary_expr',
    left,
    operator: 'LIKE',
    right,
  };
};
export const generateILike: AST.GenerateILikeAstFnInterface = ({ fieldName, filterString }) => {
  const { expr: column } = generateBasicColumn({ fieldName, alias: '' });

  return {
    type: 'function',
    name: 'ilike',
    args: {
      type: 'expr_list',
      value: [
        {
          type: 'function',
          name: 'toString',
          args: {
            type: 'expr_list',
            value: [column],
          },
          over: null,
        },
        {
          type: 'string',
          value: filterString,
        },
      ],
    },
    over: null,
  } as AST.FunctionType;
};

export const generateFilterAstsFn: (params: {
  fieldName: string;
  values: EnabledFiltersDependencyInterface['values'];
  type: EnabledFiltersDependencyInterface['type'];
  incisionRequest?: string | null;
}) => AST.WhereIn | AST.WhereBetween = ({ fieldName, values, type, incisionRequest }) => {
  let usedValues: string[] = values as string[];
  let operator: AST.AstOperatorType = 'IN';

  usedValues = usedValues.map((value) => {
    if (isString(value)) {
      return value.replace(/'/g, "''");
    }
    return value;
  });

  if (type === 'date' && !Array.isArray(values)) {
    operator = 'BETWEEN';
    const { startDate, endDate, byType } = values;

    const start = getDateByFormat(startDate, defaultDateFormat),
      end = getDateByFormat(endDate, defaultDateFormat);

    const { from, to } = getFromToFnByType[byType];

    usedValues =
      start && end
        ? [
            getStringDateByFormat(from(start), defaultSqlDateFormat) as string,
            getStringDateByFormat(to(end), defaultSqlDateFormat) as string,
          ]
        : [];

    if (byType === PeriodEnum.TODAY) {
      usedValues = [
        getStringDateByFormat(new Date(), defaultSqlDateFormat) as string,
        getStringDateByFormat(new Date(), defaultSqlDateFormat) as string,
      ];
    }
  }

  return generateIn({ values: usedValues, operator, fieldName, incisionRequest });
};

export const generateUnionWhereIn: (
  values: Array<AST.WhereIn | AST.WhereBetween | AST.WhereLike>,
) => AST.UnionAndExpressions | AST.WhereIn | AST.WhereBetween | AST.WhereLike | null = (values) => {
  if (values.length === 0) {
    return null;
  }

  if (values.length === 1) {
    return values[0];
  }

  const [left, right, ...rest] = values,
    restLength = rest.length;

  const unionLeftWhere: AST.UnionAndExpressions = {
    type: 'binary_expr',
    left,
    operator: 'AND',
    right,
  };

  if (restLength > 0) {
    const lastRestIndex = restLength - 1,
      restLeft = restLength === 1 ? unionLeftWhere : generateUnionWhereIn([left, right, ...rest.slice(0, lastRestIndex)]);

    return {
      type: 'binary_expr',
      left: restLeft,
      operator: 'AND',
      right: rest[lastRestIndex],
    } as AST.UnionAndExpressions;
  }

  return unionLeftWhere;
};

export const generateLimit: (params: { to: number }) => AST.Limit = ({ to }) => ({
  seperator: ',',
  value: [{ value: to, type: 'number' }],
});

/* Generate Object AST using sqlParser */

export const getSelectColumnsFromSqlString = (selectQuery?: string) => {
  if (selectQuery && selectQuery !== '') {
    const columns = (sqlParser.astify(`SELECT ${selectQuery}`) as Select).columns;

    if (Array.isArray(columns)) {
      return columns;
    }
  }

  return [];
};

/* Generate string from AST */

export const getFieldNameFromColumn = ({ table, column }: ColumnRef) => `${table ? `${table}.` : ''}${column}`;

export const getColumnsWithoutSelect = (columns?: Select['columns'] | AST.BasicFunctionColumn[] | AST.BasicColumn[]) =>
  sqlParser
    .sqlify({ ...defaultSelectAST, columns: columns || [] })
    .replace('SELECT', '')
    .trim();

export const getWhereString = (
  where?:
    | Array<AST.WhereIn | AST.WhereBetween>
    | AST.WhereBetween
    | AST.UnionAndExpressions
    | AST.UnionOrExpressions
    | AST.UnionExpressions
    | AST.WhereIn
    | AST.WhereLike
    | AST.FunctionType
    | null,
) => {
  let whereParam = where || null;

  if (Array.isArray(where)) {
    whereParam = generateUnionWhereIn(where);
  }

  return sqlParser
    .sqlify({ ...defaultSelectAST, where: whereParam })
    .replace('SELECT', '')
    .trim();
};

/* Check Functions */

export const isNullOrString = (value: any) => value === null || typeof value === 'string';

export const isColumnRef = (expr: any) =>
  expr?.type === 'column_ref' && isNullOrString(expr?.table) && typeof expr?.column === 'string';

export const isBasicColumn = (astValue: Select['columns'][0]) => isNullOrString(astValue?.as) && isColumnRef(astValue?.expr);

export const isBasicFunctionColumn = (astValue: Select['columns'][0]) => {
  const { expr } = astValue ?? {};

  const hasAs = isNullOrString(astValue?.as),
    isArgFunc =
      expr?.type === 'aggr_func' &&
      typeof expr?.name === 'string' &&
      isColumnRef(expr?.args?.expr) &&
      (astValue?.expr?.args?.distinct ? astValue?.expr?.args?.distinct === null : true);

  return hasAs && isArgFunc;
};
