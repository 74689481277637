import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { NoopType } from 'types/global';
import { DebouncedFunc } from 'lodash';
import { HexAndHLSColorInterface } from 'types/store';
import { HexAndHlsColorEditor } from 'modules/ui/colors/HexAndHlsColorEditor';
import SubSection from 'shared/ui/subSection';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface EditPaletteColorProps {
  activeColorData: HexAndHLSColorInterface;
  onClose: NoopType;
  onHexAndHlsColor: DebouncedFunc<(color: HexAndHLSColorInterface, type: keyof HexAndHLSColorInterface) => void>;
  onChangeCopyColor?: NoopType;
  onDeleteGroupColor?: NoopType;
  disabledDeleteGroupColor?: boolean;
}

export const EditPaletteColor = ({
  activeColorData,
  onChangeCopyColor,
  onDeleteGroupColor,
  onHexAndHlsColor,
  disabledDeleteGroupColor,
}: EditPaletteColorProps) => (
  <FlexContainer backgroundColor={`var(${ColorVarsEnum.Level_3_menu})`}>
    <MainContainerSettings>
      <SubSection>
        <GroupContainerSettings gap="18px">
          <ElementContainerSettings>
            <HexAndHlsColorEditor
              value={activeColorData}
              onChange={onHexAndHlsColor}
              onChangeCopyColor={onChangeCopyColor}
              onDeleteGroupColor={onDeleteGroupColor}
              disabledDeleteGroupColor={disabledDeleteGroupColor}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </SubSection>
    </MainContainerSettings>
  </FlexContainer>
);
