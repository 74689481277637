import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAppStoreState } from 'store/reducers/appSlice/constants';
import type { SetTitlesPayload } from 'store/reducers/appSlice/types';
import { FlowProjectInfoInterface } from 'store/reducers/projectManager/types';

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppStoreState,
  reducers: {
    setAppProjectName: (state, action: PayloadAction<string>) => {
      state.appTabTitle.title = action.payload;
      state.appTabTitle.fullName = `${state.appTabTitle.title}${
        state.appTabTitle.description ? ' - ' + state.appTabTitle.description : ''
      }`;
    },
    setAppPageTitle: (state, action: PayloadAction<string>) => {
      state.appTabTitle.description = action.payload;
      state.appTabTitle.fullName = `${state.appTabTitle.title}${
        state.appTabTitle.description ? ' - ' + state.appTabTitle.description : ''
      }`;
    },
    setAppTitles: (state, action: PayloadAction<SetTitlesPayload>) => {
      const { title, description } = action.payload;
      state.appTabTitle.title = title;
      state.appTabTitle.description = description;
      state.appTabTitle.fullName = `${title}${description ? ' - ' + description : ''}`;
    },
    updateMeta: (state, { payload: meta }: PayloadAction<FlowProjectInfoInterface>) => {
      state.meta = meta;
    },
  },
});

export const { setAppProjectName, updateMeta, setAppPageTitle, setAppTitles } = appSlice.actions;

export default appSlice.reducer;
