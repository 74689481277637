import { dayInTimestamp } from 'constants/global';
import {
  format,
  formatISO as originFormatISO,
  getMonth,
  getYear,
  isAfter as originIsAfter,
  isBefore as originIsBefore,
  lastDayOfMonth as originLastDayOfMonth,
  lastDayOfQuarter as originLastDayOfQuarter,
  lastDayOfWeek as originLastDayOfWeek,
  lastDayOfYear as originLastDayOfYear,
  parse,
  parseISO as originParseISO,
  startOfMonth as originStartOfMonth,
  startOfQuarter as originStartOfQuarter,
  startOfWeek as originStartOfWeek,
  startOfYear as originStartOfYear,
} from 'date-fns';
import ru from 'date-fns/locale/ru';
import { Option } from 'components/shared/CustomSelect/types';
import { months } from 'modules/ui/inputs/DatePicker/constants';

export const dateUtils = {
  isDayPassed: (timestamp: number | null) => {
    return typeof timestamp === 'number' && Date.now() > timestamp + dayInTimestamp;
  },
  getDateByFormat: (date: string | null, format: string) =>
    date !== null && date !== '' ? parse(date, format, new Date(), { locale: ru }) : null,
  getStringDateByFormat: (date: Date | null, formatString: string) =>
    date !== null ? format(date, formatString, { locale: ru }) : null,
  startOfMonth: originStartOfMonth,
  lastDayOfMonth: originLastDayOfMonth,
  startOfYear: originStartOfYear,
  lastDayOfYear: originLastDayOfYear,
  startOfQuarter: originStartOfQuarter,
  lastDayOfQuarter: originLastDayOfQuarter,
  startOfWeek: (value: Date) => originStartOfWeek(value, { weekStartsOn: 1 }),
  lastDayOfWeek: (value: Date) => originLastDayOfWeek(value, { weekStartsOn: 1 }),
  today: () => new Date(),
  parseISO: originParseISO,
  formatISO: originFormatISO,
  isBefore: originIsBefore,
  isAfter: originIsAfter,
};

export const {
  isDayPassed,
  getDateByFormat,
  getStringDateByFormat,
  lastDayOfQuarter,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfQuarter,
  startOfWeek,
  startOfMonth,
  today,
  parseISO,
  formatISO,
  isBefore,
  isAfter,
} = dateUtils;

type FormatDateString = {
  dateString: string | undefined;
  format: string;
  defaultValue?: string;
};

export const formatDateString = ({ dateString, format, defaultValue = '-' }: FormatDateString) => {
  if (!dateString) return defaultValue;
  const date = new Date(dateString);
  return getStringDateByFormat(date, format) || defaultValue;
};

// Функция для генерации диапазона годов в зависимости от флага и предоставленного диапазона
export const generateYearOptions = (isRealData: boolean, range?: [string, string]): Option[] => {
  const years: number[] = [];
  if (isRealData && range) {
    // Извлекаем год из каждой даты и создаем массив годов в указанном диапазоне
    const startYear = getYear(new Date(range[0]));
    const endYear = getYear(new Date(range[1]));

    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
  } else {
    // По умолчанию создаем диапазон от 1990 до текущего года + 2
    const currentYear = getYear(new Date());
    const startYear = currentYear + 2;
    for (let year = startYear; year >= 1990; year--) {
      years.push(year);
    }
  }

  return years.map((year) => ({
    value: String(year),
    title: String(year),
  }));
};

export const getMonthOptions = (isRealData: boolean, date: Date, range?: string[]): Option[] => {
  if (isRealData && range && range[0] && range[1]) {
    const startDate = parseISO(range[0]);
    const endDate = parseISO(range[1]);

    const dateYear = getYear(date);
    const startYear = getYear(startDate);
    const endYear = getYear(endDate);
    const dateMonth = getMonth(date);
    const startMonthIndex = getMonth(startDate);
    const endMonthIndex = getMonth(endDate);

    const isAfterStart = dateYear > startYear || (dateYear === startYear && dateMonth >= startMonthIndex);
    const isBeforeEnd = dateYear < endYear || (dateYear === endYear && dateMonth <= endMonthIndex);

    if (!isAfterStart || !isBeforeEnd) {
      return []; // Возвращаем пустой массив, если дата вне диапазона
    }

    // Формируем месяцы в зависимости от года и диапазона
    let startMonth = 0;
    let endMonth = 11;

    if (dateYear === startYear) {
      startMonth = startMonthIndex;
    }
    if (dateYear === endYear) {
      endMonth = endMonthIndex;
    }

    return months.slice(startMonth, endMonth + 1).map((title, index) => ({
      value: String(startMonth + index),
      title,
    }));
  }

  return months.map((title, index) => ({
    value: String(index),
    title,
  }));
};
export const findMonthByYear = (dates: string[], year: number): number | null => {
  for (const dateString of dates) {
    const date = new Date(dateString);

    if (date.getFullYear() === year) {
      return date.getMonth();
    }
  }
  return null;
};
