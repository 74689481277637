import { EntityType, IdInterface, NameInterface, UsersAndGroupInterface } from 'types/store';
import { MoveToType } from 'utils/utils';
import { BackgroundImageSelectorProportionsInterface, IsActiveInterface } from '../visualisations/types';
import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ValueOf } from 'types/global';
import { VariableExpandedStatus, VariableStatus, VariablesType } from './constants';

export interface PageBoardSettingsSizesInterface {
  height: number;
  width: number;
}

export interface PageBoardSettingsInterface {
  sizes: PageBoardSettingsSizesInterface;
}

export interface UsersAnfGroupsInterface extends NameInterface, IdInterface {
  entity?: EntityType;
}

export interface AccessSettingsInterface {
  users: UsersAnfGroupsInterface[];
  groups: UsersAnfGroupsInterface[];
}

export interface PageInterface extends IdInterface {
  name: string;
  isHidden: boolean;
  boardSettings: PageBoardSettingsInterface;
  accessSettings: AccessSettingsInterface;
  isShowWorkSpace: boolean;
  color: IsActiveInterface & {
    color: PaletteValuesByThemeType | ColorValuesByThemeType | null;
  };
  image: BackgroundImageSelectorProportionsInterface & {
    link: string;
  };
  variables: ParentsVariableInterface[];
}

export interface ParentsVariableInterface {
  id: string;
  name: string;
  type: 'string' | 'boolean';
  status: commonTypeVariable;
}

export type commonTypeVariable = string | VariableStatusType;

export interface PagesStateInterface {
  projectPages: PageInterface[];
  serverStateOfProjectPages: PageInterface[] | null;
  firstPageId: string | null;
  activePageId: string;
  pagesLoading: boolean;
  rlsUserGroupAnfGroup: UsersAndGroupInterface[];
}

/* Action Types */

export enum PagesActionsTypes {
  UPLOAD_PAGES_POSITION = 'UPLOAD_PAGES_POSITION',
  LOAD_CHECKING_ACCESS_PAGE = 'LOAD_CHECKING_ACCESS_PAGE',
  ADD_PAGE = 'ADD_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',
  LOAD_PAGES = 'LOAD_PAGES',
  LOAD_PAGES_FROM_SNAPSHOT = 'LOAD_PAGES_FROM_SNAPSHOT',
  LOAD_FIRST_PAGE_ID = 'LOAD_FIRST_PAGE_ID',
  LOAD_FIRST_BY_PAGE_ID = 'LOAD_FIRST_BY_PAGE_ID',
  COPY_PAGE = 'COPY_PAGE',
  ADD_PAGE_BY_DATA = 'ADD_PAGE_BY_DATA',
  CLEAR_PROJECT_PAGES_STORE = 'CLEAR_PROJECT_PAGES_STORE',
  UPDATE_PAGE_BY_ID = 'UPDATE_PAGE_BY_ID',
  LOAD_VIEWER_USERS_AND_GROUPS = 'LOAD_VIEWER_USERS_AND_GROUPS',
  LOAD_RLS_USER_AND_GROUP = 'LOAD_RLS_USER_AND_GROUP',
  EXPORT_PAGES_JSON_ACTION = 'EXPORT_PAGES_JSON_ACTION',
  IMPORT_PAGES_ACTION = 'IMPORT_PAGES_ACTION',
  ADD_BOOLEAN_VARIABLE_TO_PAGE_BY_ID = 'ADD_BOOLEAN_VARIABLE_TO_PAGE_BY_ID',
  ADD_STRING_VARIABLE_TO_PAGE_BY_ID = 'ADD_STRING_VARIABLE_TO_PAGE_BY_ID',
  UPDATE_VARIABLE_TO_PAGE_BY_ID = 'UPDATE_VARIABLE_TO_PAGE_BY_ID',
  DELETE_VARIABLE_TO_PAGE_BY_ID = 'DELETE_VARIABLE_TO_PAGE_BY_ID',
}

export interface UpdatePageByIdPayload extends IdInterface {
  page: Omit<PageInterface, 'id'>;
}

export interface UpdatePagePositionPayload extends IdInterface {
  moveTo: MoveToType;
}

export interface ExportPagesInterface {
  projectId: string;
  data: FastBoard.API.ExportAndImportPagesDto;
}

export interface ImportPagesInterface<TypeFile> {
  projectId: string;
  file: TypeFile;
  importModels: boolean;
  newPages: boolean;
}

export interface CheckingAccessPayload {
  projectId: string;
  pageId: string;
}

export type VariablesStatusTypes = 'true' | 'false';
export type VariablesExpandedStatusTypes = VariablesStatusTypes | 'invert';
export type VariablesTypes = 'string' | 'boolean';

export type VariableStatusType = ValueOf<typeof VariableStatus>;
export type VariableExpandedStatusType = ValueOf<typeof VariableExpandedStatus>;
export type VariableTypesType = ValueOf<typeof VariablesType>;
