import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { SnackbarProvider } from 'notistack';

export const defaultScrollbarMixin = css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transform: rotate(90deg);
    top: 20px;
    right: 20px;
    position: absolute;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${`var(${ColorVarsEnum.Level_5})`};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${`var(${ColorVarsEnum.Level_4})`};
  }
`;

export const pathCircleMixin = (color = `var(${ColorVarsEnum.Level_3})`, fullColor?: boolean) => css`
  path,
  circle,
  rect {
    fill: ${fullColor ? color : null};
    stroke: ${color};
    transition: all 450ms;
    vector-effect: non-scaling-stroke;
  }
`;

export const ellipsisMixin = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const bottomLineStyleMixin = css`
  height: 1px;
  width: 100%;
  background-color: var(${ColorVarsEnum.Level_5});
`;

export const bottomLineMixin = css`
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  ${bottomLineStyleMixin};
`;

export const backgroundOpacityMixin = css`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0.03;
  z-index: -1;
`;

export const raisingLabelMixin = css`
  transform: translateY(-30px);
  font-size: 10px;
  font-family: 'Roboto', sans-serif;
  left: 0;
  padding: 0;
`;

export const defaultDropDownStyleMixin = css`
  background-color: var(${ColorVarsEnum.Level_4_widget});
  border-radius: 8px;
  border: 1px solid var(${ColorVarsEnum.Level_5});
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

export const defaultSelectedListItem = css`
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  background-color: var(${ColorVarsEnum.Accent});
  z-index: -1;
`;

export const safeAreaInsetBottom = css`
  @supports (padding: max(0px)) {
    padding-bottom: max(100px, env(safe-area-inset-bottom));
  }
`;

export const fileInputMixin = css`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const SnackbarProviderContainer = styled(SnackbarProvider)`
  overflow: auto;
  max-height: 80vh;
  overflow-wrap: anywhere;
`;

export const SnackbarCloseIconWrapper = styled.div`
  svg path {
    stroke: #ffffff;
  }

  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const SnackbarErrorWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

export const backgroundColorMixin = css`
  content: '';
  position: absolute;
  background-color: ${`var(${ColorVarsEnum.Level_1})`};
  opacity: 0.05;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
`;
