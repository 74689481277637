import styled, { css } from 'styled-components';
import { CursorType } from 'types/styles';
import { pathCircleMixin } from 'constants/styles';

export interface StyledIconWrapperProps {
  cursor?: CursorType;
  containerHeight?: string;
  containerWidth?: string;
  iconHeight?: string;
  iconWidth?: string;
  colorStroke?: string;
  hoverColorStroke: string;
  disableDefaultStyles?: boolean;
  margin?: string;
  fullColor?: boolean;
  rotate?: string;
}

/* TODO: Leave hover, maybe return it later */
export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${({ margin }) => margin && `${margin}`};
  cursor: ${({ cursor }) => cursor || 'pointer'};
  rotate: ${({ rotate }) => rotate};

  ${({ disableDefaultStyles }) =>
    !disableDefaultStyles &&
    css<StyledIconWrapperProps>`
      ${({ containerHeight }) => containerHeight && `height: ${containerHeight};`};
      ${({ containerWidth }) => containerWidth && `width: ${containerWidth};`};

      // :hover {
      //   svg {
      //     path,
      //     circle,
      //     rect {
      //       stroke: ${({ hoverColorStroke }) => hoverColorStroke};
      //     }
      //   }
      // }
      cursor: ${({ cursor }) => cursor || 'pointer'};

      svg {
        height: ${({ iconHeight }) => iconHeight || '14px'};
        width: ${({ iconWidth }) => iconWidth || '14px'};

        ${({ colorStroke, fullColor }) => pathCircleMixin(colorStroke, fullColor)}
      }
    `}
`;
