import { ThemeItemInterface } from 'store/reducers/themes/types';
import { EyeIcon } from 'assets/icons/withContainer';
import React from 'react';
import { NoopType, NoopValueType } from 'types/global';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { SettingsPagesFieldEntry } from 'modules/settingsContainer/SettingsPagesFieldEntry';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

interface ThemesListSettingsProps {
  themes: ThemeItemInterface[];
  activeThemeId: string | null;
  onThemeNameChange: NoopValueType<ThemeItemInterface>;
  onActiveThemeChange: NoopValueType<ThemeItemInterface>;
  onDeleteTheme: NoopValueType<string>;
  onAddTheme: NoopType;
}

export const ThemesListSettings = ({
  themes,
  activeThemeId,
  onThemeNameChange,
  onActiveThemeChange,
  onAddTheme,
  onDeleteTheme,
}: ThemesListSettingsProps) => {
  const { controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected } = useDataSettingsMetric();

  const themeRender: MetricsRenderType<ThemeItemInterface> = ({ metrics }) => (
    <>
      {metrics.map((theme) => {
        const { name, id } = theme;

        const isActiveTheme = activeThemeId === id;

        return (
          <SettingsPagesFieldEntry
            disabledOptionsSourceMenu
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            disablePriority
            EyeIcon={
              <IconWrapper
                iconWidth="17px"
                iconHeight="17px"
                containerHeight="30px"
                containerWidth="30px"
                colorVar={isActiveTheme ? ColorVarsEnum.Accent : undefined}
                hoverColorVar={isActiveTheme ? ColorVarsEnum.Accent : undefined}
                Icon={EyeIcon}
              />
            }
            fieldValue={name}
            onFieldChange={(name) => onThemeNameChange({ ...theme, name })}
            onClickHidden={() => onActiveThemeChange(theme)}
            key={id}
          />
        );
      })}
    </>
  );

  return (
    <MetricSettings
      addButtonText="Создать тему"
      metricRender={themeRender}
      metrics={themes}
      onAdd={onAddTheme}
      onDelete={(id) => id && onDeleteTheme(id)}
      maxMetricListHeight="400px"
      controls={controls}
    />
  );
};
