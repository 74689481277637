import { NoopValueType } from 'types/global';
import { TextField } from 'modules/ui';
import { FlexContainer } from 'styles/FlexContainer';
import { IProps } from 'modules/ui/TextField';

interface NameSettingsProps extends Partial<IProps> {
  value?: string;
  onChange?: NoopValueType<string>;
}

export const NameSettings = ({ value, onChange, formattingValue, ...paramsTextField }: NameSettingsProps) => {
  const onLocalChange = (name: string) => {
    onChange && onChange(name);
  };

  return (
    <FlexContainer width="100%">
      <TextField
        width="100%"
        name="object-name-field"
        value={value}
        useDebounce
        onChange={(e) => onLocalChange(formattingValue ? formattingValue(e.target.value) : e.target.value)}
        formattingValue={formattingValue}
        {...paramsTextField}
      ></TextField>
    </FlexContainer>
  );
};
