import { DeleteButtonWrapper } from 'modules/ui/buttons/AddColorButton/styles';
import styled from 'styled-components';

export type PickerItemWrapperProps = {
  pickerItemDirection?: 'column' | 'row';
  minHeight?: string;
};

export const PickerItemWrapper = styled.div<PickerItemWrapperProps>`
  display: flex;
  flex-direction: ${({ pickerItemDirection }) => pickerItemDirection || 'column'};
  min-height: ${({ minHeight }) => minHeight || ' 48px'};
  gap: 4px;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    span {
      display: none;
    }

    ${DeleteButtonWrapper} {
      display: flex;
    }
  }
`;
